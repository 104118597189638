import * as React from "react";
import {utils} from "../../services/utils";
import moment from "moment";
import {BaseComponent} from "../../components/basecomponent";
import {BreadCrumb} from "../../components/breadcrumb";
import {Button, Tag} from "antd";
import {DataTable} from "../../components/datatable";

export class Customerslist extends BaseComponent {

    constructor(props, context) {
        super(props, context);
        this.state = {
            customers: null,
            urlParams: {
                sort: "id,desc",
                size: 10
            },
        }
    };

    urls = {
        customers: "/customers?page=0&sort=id,desc&size=10"
    };

    componentDidMount(){
        this.getCustomers();
    }

    getCustomers(){
        this.get(this.buildUrl(this.urls.customers))
            .then(data => {
                utils.logger("customers", data);

                this.setState({
                    customers: data.body
                })
            }).catch(e => utils.logger(e))
    }

    getRecords = (records) => {
        utils.logger(records);
        this.setState({
            customers: records.body
        })
    };

    header = (state) => {
        return (
          <>
              <div className="row table-header">
                  <div className="col">Customer</div>
                  <div className="col">Details</div>
                  <div className="col">Financial</div>
                  <div className="col">Record</div>
              </div>
              <hr/>
          </>
        )
    };

    row = (row, state) => {
        return (
          <>
              <div className="row">
              <div className="col">
                  <div> <strong>First Name:</strong> {row.firstName}</div>
                  <div> <strong>Last Name:</strong> {row.lastName}</div>
                  <div> <strong>ID Number:</strong> {row.idNumber}</div>
                  <div> <strong>Customer Type:</strong> {row.customerType}</div>
              </div>
              <div className="col">
                  <div><strong>Marital Status:</strong> {row.maritalStatus}</div>
                  <div><strong>Mobile Phone:</strong> {row.mobilePhone}</div>
                  <div><strong>Address:</strong> {row.address}</div>
              </div>
              <div className="col">
                  <div><strong>Bank:</strong> {row.bank ? row.bank.name : ""}</div>
                  <div><strong>Branch:</strong> {row.bankBranch}</div>
                  <div><strong>Bank Acc Number:</strong> {row.bankAccountNumber}</div>
                  <div><strong>Net Salary:</strong> {row.netMonthlySalary}</div>
              </div>
              <div className="col">
                <div>ID: {row.id}</div>
                <div>Created: {moment(row.created).format("DD-MM-YYYY H:mm:s")}</div>
                <div>
                  Status: &nbsp;
                      {row.active && <Tag color="green" className="mr-2">Active</Tag> }
                      {!row.active && <Tag color="red" className="mr-2">Inactive</Tag> }
                </div>
              </div>
              </div>
              <hr/>
          </>
        )
    };

    pagination = (state) => {
        return super.pagination(this.state.customers, this.getCustomers)
    };

    render(){
        return (<>
        <BreadCrumb
          crumbs={[
              {label: 'Customers', link: '#'},
          ]}
        />
        <div className="row mx-0 d-flex align-items-center justify-content-center">
            <div className="col-9 py-5">
                <Button type="primary"
                        loading={this.state.loading}
                        disabled={this.state.disabled}
                        onClick={()=>this.props.history.push("/customers/new")}>Create New Customer</Button>

                <div className={`ui ` + (this.state.loading && `active loader`)}/>
                <DataTable
                  {...this.props}
                  options={{
                      data: this.state.customers?this.state.customers.content:[],
                      header: this.header,
                      row: this.row,
                      pagination: this.pagination,
                      actions: [
                          {
                              bulk: false,
                              label: "View",
                              onAction: (target) =>
                                  this.props.history.push('/customers/view/'+target.id)
                          },
                          {
                              bulk: false,
                              label: "Edit",
                              onAction: (target) =>
                                  this.props.history.push('/customers/change/'+target.id)
                          },
                      ],
                      search: {
                          onSearch: (searchString) => {
                              if(!searchString) return;
                              this.addUrlParams({
                                  "page": 0,
                                  "firstName": "EQ,"+searchString+",AND",
                                  "lastName": "EQ,"+searchString+",OR",
                                  "idNumber": "EQ,"+searchString+",OR",
                              }).then(()=>this.getCustomers());
                          },
                          onReset: ()=> {
                              let params = this.state.urlParams;
                              params = delete params.name;
                              this.addUrlParams(params)
                                .then(()=>this.getCustomers());
                          }
                      },
                      filters: {
                          display: this.state.urlParams,
                          fields: [
                              { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                          ],
                          onAdd: (name, value)=>{
                              if(!value) return;
                              let params = utils.copy(this.state.urlParams);
                              params[name] = "LK,"+value+",AND";
                              params.page = 0;
                              this.addUrlParams(params)
                                  .then(()=>this.getCustomers());
                          },
                          onRemove: (name) => {
                              let params = this.state.urlParams;
                              params = delete params[name];
                              this.addUrlParams(params)
                                .then(()=>this.getCustomers());
                          },
                      }
                  }}
                />

            </div>
        </div>
        </>);
    }
}
