import React from 'react';
import {BaseComponent} from "../../components/basecomponent";
import {DataTable} from "../../components/datatable";
import moment from "moment";
import {Button, Tag, Upload} from "antd";
import Modal from "react-awesome-modal";
import { InboxOutlined } from '@ant-design/icons';
import {https} from "../../services/https";
import {utils} from "../../services/utils";
import {BreadCrumb} from "../../components/breadcrumb";

const { Dragger } = Upload;

export class Files extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      files: {},
      urlParams: {
        size: 10,
        sort: 'id,desc'
      },
      currentPage: 0,
      upload: false,
      fileList: []
    }
  }

  urls = {
    files: '/files',
    upload: https.serverUrl + '/files',
    action: '/files/action/{action}/{id}'
  };

  componentDidMount() {
    this.getFiles();
  }

  change(action, id) {
    this.get(
        this.urls.action
            .replace("{action}", action)
            .replace("{id}", id))
        .then(data => {
          if(!data) return;
          this.getFiles();
        });
  }

  getFiles = () => {
    this.get(this.buildUrl(this.urls.files))
      .then(res => {
        if(!res) return;
        this.setState({files: res.body});
      })
  };

  header = (state) => {
    return (
    <>
      <div className="row table-header">
        <div className="col">File Details</div>
        <div className="col">Record Details</div>
      </div>
      <hr/>
    </>
    )
  };

  row = (row, state) => {
    return(
      <>
        <div className="row">
          <div className="col">
            File name: {row.name} <br/>
            Record Count: {row.records} <br/>
            Total amount: {row.amount} <br/>
          </div>
          <div className="col">
            ID: {row.id} <br/>
            Created: {moment(row.created).format("YYYY-MM-DD hh:mm:ss")} <br/>
            Status: &nbsp;
            { !row.rejected && row.authorized && <Tag color="green" className="mr-2">Authorized</Tag> }
            { !row.rejected && !row.authorized && <Tag color="orange" className="mr-2">Pending</Tag>}
            { row.rejected && <Tag color="red" className="mr-2">Rejected</Tag>}
          </div>
        </div>
        <hr/>
      </>
    )
  };

  pagination = (state) => {
    return super.pagination(this.state.files, this.getFiles)
  };

  beginUpload(){
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('file', file);
    });

    this.toggle(true);
    https.postFile(this.urls.files, formData)
      .then(res => {
        utils.logger("action", res);
        this.toggle(false);
        this.handlePostResponse(res, true, null, "File uploaded successfully")
          .then((data)=>{
            if(!data) return;
            this.setState({ fileList: [] });
            this.getFiles();
            this.toggleModal("upload");
          })
      });
  }

  render() {
    return (
      <div>
        <BreadCrumb
            crumbs={[
              {label: 'Files', link: '#'},
            ]}
        />
        <div className="row mx-0 d-flex align-items-center justify-content-center">
          <div className="col-9 py-5">
            <Button type="primary"
                    loading={this.state.loading}
                    disabled={this.state.disabled}
                    onClick={()=>this.toggleModal("upload")}>Upload File</Button>

            <div className={`ui ` + (this.state.loading && `active loader`)}/>
            <DataTable
              {...this.props}
              options={{
                data: this.state.files.content,
                header: this.header,
                row: this.row,
                pagination: this.pagination,
                actions: [
                  {
                    bulk: false,
                    label: "View",
                    onAction: (target) => {
                      this.props.history.push('/files/view/'+target.id);
                    }
                  },
                  {
                    bulk: true,
                    label: "Download",
                    onAction: (target) => {
                      window.location.href = https.serverUrl + '/files/download/file/' + target.id + '?access_token=' + utils.get('token', false);
                    }
                  },
                  {
                    bulk: true,
                    label: "Download Exceptions",
                    onAction: (target) => {
                      window.location.href = https.serverUrl + '/files/download/errors/file/' + target.id + '?access_token=' + utils.get('token', false);
                    }
                  },
                  {
                    bulk: true,
                    label: "Authorize",
                    active: (target) => !target.rejected && !target.authorized,
                    onAction: (target)=> {
                      if(!target.rejected && !target.authorized)
                        this.change("authorize", target.id)}
                  },
                  {
                    bulk: true,
                    label: "Reject",
                    active: (target) =>  (!target.rejected && !target.authorized),
                    onAction: (target)=> {
                      if(!target.rejected && !target.authorized)
                        this.change("reject", target.id)}
                  },
                ],
                search: {
                  onSearch: (searchString) => {
                    if(!searchString) return;
                    this.addUrlParams({
                      name: "LK,"+searchString+",OR"
                    }).then(()=>this.getFiles());
                  },
                  onReset: ()=> {
                    let params = this.state.urlParams;
                    params = delete params.name;
                    this.addUrlParams(params)
                      .then(()=>this.getFiles());
                  }
                },
                filters: {
                  display: this.state.urlParams,
                  fields: [
                    { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                    { label: "Date", name: "created", type: "input" },
                  ],
                  onAdd: (name, value)=>{
                    if(!value) return;
                    this.addUrlParams({
                      [name]: this.mapValue(name, value)
                    }).then(()=>this.getFiles());
                  },
                  onRemove: (name) => {
                    let params = this.state.urlParams;
                    params = delete params[name];
                    this.addUrlParams(params)
                      .then(()=>this.getFiles());
                  },
                }
              }}
            />


            {/* MAKE PAYMENT MODAL */}
            <Modal visible={this.state.upload} width="400"
                   effect="fadeInUp" onClickAway={() => this.toggleModal("upload")}>
              <div className="segment px-3 py-4">
                <h5>Upload an SSB File</h5>
                <hr/>
                <Dragger
                  name="file"
                  multiple={false}
                  onRemove = {(file) => {
                    this.setState(state => {
                      const index = state.fileList.indexOf(file);
                      const newFileList = state.fileList.slice();
                      newFileList.splice(index, 1);
                      return {
                        fileList: newFileList,
                      };
                    });
                  }}
                  beforeUpload ={ file => {
                    this.setState(state => ({
                      fileList: [...state.fileList, file],
                    }));
                    return false;
                  }}
                  fileList={this.state.fileList}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">Click or drag file to this area to upload</p>
                  <p className="ant-upload-hint">
                    Support for a single upload.
                  </p>
                </Dragger>
                <Button
                  className="mt-3"
                  type="primary"
                  loading={this.state.loading}
                  disabled={this.state.disabled}
                  onClick={()=>this.beginUpload()}
                >Begin Upload</Button>
              </div>
            </Modal>

          </div>

        </div>

      </div>
    );
  }
}
