import React, {Component} from 'react';
import { Breadcrumb } from 'antd';
import {Link} from "react-router-dom";

export class BreadCrumb extends Component {
  render() {
    return (
      <div className="mx-5 px-5 mt-4">
      <Breadcrumb>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        {this.props.crumbs.map((v, i)=>{
        return <Breadcrumb.Item key={i}>
          <Link to={v.link}>{v.label}</Link>
        </Breadcrumb.Item>
        })}
      </Breadcrumb>
      </div>

    );
  }
}
