import Swal from "sweetalert2";

const Toast = Swal.mixin({
  toast: true,
  position: 'bottom',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
});

export class Notify {
  static fire(title, body, type){
    Toast.fire(
      title,
      body,
      type
    )
  }

  static confirm(title, body, type, callback){
    Swal.fire({
      title: title,
      text: body,
      icon: type,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Go ahead!'
    }).then((result) => {
      if (result.value) {
        callback();
      }
    })
  }

  static emptyNotif(){
    return {title: "", message: "", type: ""}
  }
}
