import {utils} from "./utils";

export class HTTP {
    // serverUrl = "http://localhost:8030";
    serverUrl = "https://mfc.twentiescapital.co.zw";

    pageSize = 5;

    get(url){
        utils.logger(url);
        return fetch(`${this.serverUrl}${url}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getUser(){
        return fetch(`${this.serverUrl}/user`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getUri(href){
        utils.logger(href);
        return fetch(`${href}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getPaginated(uri, filter=''){
        utils.logger([uri, filter]);
        return fetch(`${this.serverUrl}${uri}?size=${this.pageSize}${filter}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getLoans(){
        // utils.logger(data);
        return fetch(`${this.serverUrl}/loans`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    updateUri(data){
        utils.logger(data);
        return fetch(`${data._links.self.href}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    createLoan(data){
        utils.logger(data);
        return fetch(`${this.serverUrl}/loans`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getAccounts(){
        // utils.logger(data);
        return fetch(`${this.serverUrl}/accounts`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    createAccount(data){
        utils.logger(data);
        return fetch(`${this.serverUrl}/accounts`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getCustomers(){
        // utils.logger(data);
        return fetch(`${this.serverUrl}/customers`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
            // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    createCustomer(data){
        utils.logger(data);
        return fetch(`${this.serverUrl}/customers`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
            // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getBanks(){
        // utils.logger(data);
        return fetch(`${this.serverUrl}/banks`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    getBranches(){
        // utils.logger(data);
        return fetch(`${this.serverUrl}/branches`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    login(data){
        return fetch(`${this.serverUrl}/oauth/token?grant_type=password&username=${data.username}&password=${data.password}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dGVzdDp0ZXN0MTIz' // test:test123
            },
            // body: JSON.stringify(data)
        })
            // .then(res => res.json())
            .catch(err => utils.logger(err))
    }
}
