import React, {Component} from 'react';
import moment from "moment";

export class Footer extends Component {
    render() {
        return (
            <div className="text-center" {...this.props}>
                <p className="small">&copy; {moment(new Date()).format("Y")} MFC Online</p>
            </div>
        );
    }
}