import * as React from "react";
import {utils} from "../../services/utils";
import DatePicker from "react-datepicker/es";
import moment from "moment";
import {Footer} from "../../components/footer";
import {BaseComponent} from "../../components/basecomponent";
import {BreadCrumb} from "../../components/breadcrumb";
import {Button, Select} from "antd";

const { Option } = Select;

export class NewLoan extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                customerIdentifier: '',
                loanDate: new Date(),
                refNumber: '',
                principal: 0,
                interestRate: 12,
                calculationMode: '',
                firstPaymentDate: new Date(),
                numberOfRepayments: 1,
                // period: '',
                monthEnd: 30,
                lastPaymentDate: new Date(moment().add(1, 'month').format("YYYY-MM-DD")),
                tenure: 1,
                interest: 0,
                totalRepayment: 0,
                daysTillFirstPayment: 0,
                loanDuration: 0,
                loanAppliedFor: 0,
                administrativeRate: 7
            },
            customers: [],
            installment: 0,
            errors: null,
            urlParams: {
                size: 10,
                sort:'id,desc'
            },
            status: 'ACTIVE'
        }
    }

    href = this.props.match.params.href;
    loanId = this.props.match.params.loanId;
    quote = this.props.match.params.quote;

    urls = {
        customers: "/customers",
        loans: "/loans",
        editLoan: "/loans/edit",
    };

    componentDidMount() {
        this.addUrlParams({size: 10}).then(()=>{
            this.getCustomers(this.urls.customers);
        });
        //if its an edit
        if(this.loanId) this.getLoan();
        // if its a loan saved from the calculator
        try {
            if(localStorage.getItem('savedLoan')) {
                this.setState({
                    model: NewLoan.refactorLoan(JSON.parse(localStorage.getItem('savedLoan')))
                });
                localStorage.removeItem('savedLoan');
            }
        } catch(e){
            utils.logger(e);
        }
    }

    getLoan(){
        this.get(this.urls.loans+"/"+this.loanId)
            .then(data => {
                utils.logger("loan: ", data);
                data = NewLoan.refactorLoan(data.body);

                data.customerIdentifier = data.customer ? data.customer.id : null;
                utils.log(data);

                this.setState({
                    model: data,
                }, ()=> this.calculate());
            })
    }

    static refactorLoan(data) {
        data.firstPaymentDate = new Date(data.firstPaymentDate);
        data.lastPaymentDate = new Date(data.lastPaymentDate);
        data.loanDate = new Date(data.loanDate);
        data.loanAppliedFor = data.loanAppliedFor ? data.loanAppliedFor : 0;
        data.administrativeRate = data.administrativeRate ? data.administrativeRate : 0;
        return data;
    }

    getCustomers = (url) => {
        this.get(this.buildUrl(url))
            .then(data => {
                utils.logger("customers", data);
                if(!this.loanId)
                    this.updateModel('customerIdentifier', data.body.content[0] ? data.body.content[0].id : null);
                this.setState({
                    customers: data.body.content,
                });
                this.calculate();
            })
    };

    submitLoan = (e) => {
        e.preventDefault();
        utils.logger(this.state.model);

        let model = JSON.parse(JSON.stringify(this.state.model));

        let res;
        if(!this.loanId) res = this.action(this.urls.loans, model, "Loan created Successfully", null , false);
        else res = this.action(this.urls.editLoan, model, "Loan created Successfully", null , false);

        res.then(data => {
                utils.logger(data);
                if(!data.body) return this.setState({ errors: data.errors });

                this.props.history.push('/loans/list');
            })
    };

    updateModel(f, v){
        utils.logger("fv: ", f,v);
        let model = this.state.model;
        model[f] = v;
        this.setState({ model: model }, ()=>{
            let calculateIf = ['principal', 'interestRate', 'firstPaymentDate',
                'numberOfRepayments', 'loanAppliedFor', 'administrativeRate'];
            if(calculateIf.includes(f)){
                this.calculate();
            }
        });
    }

    calculate(){
        let model = this.state.model;

        if(model.loanAppliedFor){
            // adjust principal using the administrative costs + loan applied for
            let administrativeCost = model.loanAppliedFor * (model.administrativeRate / 100);
            model.principal = model.loanAppliedFor + administrativeCost;
        }

        // first calculate daily interest rate
        let dailyInt = (model.interestRate / 30) / 100;

        // then calculate lastPaymentDate in order to use to determine loan duration in days
        model.lastPaymentDate = moment(model.firstPaymentDate).add(model.numberOfRepayments - 1 , 'months').format('YYYY-MM-DD');
        const diff = moment(model.lastPaymentDate).diff(moment(model.firstPaymentDate), 'days');
        utils.logger(diff);
        model.lastPaymentDate = diff > 1 ? model.lastPaymentDate : model.firstPaymentDate;

        // calculate loan duration in days based on today and the lastPaymentDate
        model.loanDuration = moment(model.lastPaymentDate).diff(model.loanDate, 'days');

        // if today is less than 30 days change it to 30 since minimum loan duration is 30 days
        model.loanDuration = model.loanDuration < 30 ? 30 : model.loanDuration;

        // calculate interest charged per dollar
        let totalInterest = model.loanDuration * dailyInt;

        // calculate total interest to be charge based on principal
        model.interest = Math.ceil(model.principal * totalInterest);

        // calculate total amount to be repaid
        // account for administration fees to adjust principal
        model.totalRepayment = model.principal + model.interest;

        // let monthsTill = moment(model.lastPaymentDate).diff(model.loanDate, 'months');
        let monthsTill = model.numberOfRepayments;
        let t = (monthsTill === 0) ? 1 : monthsTill;
        let installment = Math.ceil(model.totalRepayment / t);
        model.tenure = t;

        // calculate daysTillFirstPayment
        model.daysTillFirstPayment = moment(model.firstPaymentDate).diff(moment(model.loanDate), 'days');

        utils.logger([model.totalRepayment, t]);

        this.setState({ model: model, installment: installment });

        // utils.logger([model.principal, model.interestRate, model.numberOfRepayments, model.firstPaymentDate, installment]);

    }

    customerItemTemplate = (customer) => {
        return `${customer.firstName} ${customer.lastName} - ${customer.accountNumber}`;
    };

    render(){
        return (
            <div>
                <BreadCrumb
                  crumbs={[
                      {label: 'Loans', link: '/loans/list'},
                      {label: this.id ? 'Edit Loan' : 'New Loan', link: '#'},
                  ]}
                />
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-9 py-5">
                        <div className="">
                            <div className="mx-5">
                                <h3 className="mb-4">New Loan Form</h3>
                                <hr/>
                                <form onSubmit={(e)=>this.submitLoan(e)}>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-row">
                                                { !this.quote && <div className="form-group col-6">
                                                    <label>Customer</label><br/>
                                                    <Select
                                                      loading={this.state.loading}
                                                      showSearch
                                                      value={this.state.model.customerIdentifier}
                                                      placeholder={"Search Customer"}
                                                      className='w-100'
                                                      defaultActiveFirstOption={false}
                                                      showArrow={false}
                                                      filterOption={false}
                                                      onSearch={(value) => {
                                                          utils.logger("search param", value);
                                                          this.addUrlParams({
                                                              "size": 20,
                                                              "firstName": `LK,${value},OR`,
                                                              "lastName": `LK,${value},OR`,
                                                              "accountNumber": `LK,${value},OR`
                                                          })
                                                            .then(()=>{
                                                                // utils.logger("theurl", this.buildUrl(this.urls.customers));
                                                                this.getCustomers(this.urls.customers);
                                                            });
                                                      }}
                                                      onChange={(value)=>{
                                                          utils.logger("chosen", value);
                                                          this.updateModel("customerIdentifier", value);
                                                      }}
                                                      notFoundContent={null}
                                                    >
                                                        {this.state.customers.map((v,i)=>{
                                                            return <Option key={v.id}>{`${v.firstName} ${v.lastName} - ${v.id}`}</Option>;
                                                        })}
                                                    </Select>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.customer}</small>}
                                                </div> }
                                                <div className="form-group col-6">
                                                    <label>Loan Date</label><br/>
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText="Enter Loan Date"
                                                        selected={this.state.model.loanDate}
                                                        // minDate={new Date()}
                                                        onChange={(date)=> this.updateModel('loanDate', date)}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.loanDate}</small>}
                                                </div>
                                            </div>
                                            {/*<div className="form-group">*/}
                                            {/*    <label>Ref Number </label>*/}
                                            {/*    <input type="text" className="form-control" placeholder="Enter Ref Number"*/}
                                            {/*        onChange={(e)=> this.updateModel('refNumber', e.target.value) }*/}
                                            {/*        value={this.state.model.refNumber}/>*/}
                                            {/*    { this.state.errors && <small className="text-danger">{this.state.errors.refNumber}</small>}*/}
                                            {/*</div>*/}
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label>Loan applied for </label>
                                                    <input type="number" className="form-control" placeholder="Enter Principal"
                                                           onChange={(e)=> this.updateModel('loanAppliedFor', parseInt(e.target.value)) }
                                                           value={this.state.model.loanAppliedFor}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.loanAppliedFor}</small>}
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Administrative Rate <small>(%)</small> </label>
                                                    <input type="number" step=".01" className="form-control" placeholder="Enter Interest Rate"
                                                           onChange={(e)=> this.updateModel('administrativeRate', parseInt(e.target.value)) }
                                                           value={this.state.model.administrativeRate}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.administrativeRate}</small>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label>Principal </label>
                                                    <input type="number" className="form-control" placeholder="Enter Principal"
                                                           disabled
                                                           value={this.state.model.principal}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.principal}</small>}
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Interest Rate <small>(%)</small> </label>
                                                    <input type="number" className="form-control" placeholder="Enter Interest Rate"
                                                           onChange={(e)=> this.updateModel('interestRate', parseInt(e.target.value)) }
                                                           value={this.state.model.interestRate}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.interestRate}</small>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-4">
                                                    <label>First Repayment Date </label>
                                                    <DatePicker
                                                        className="form-control"
                                                        placeholderText="Enter Loan Date"
                                                        selected={this.state.model.firstPaymentDate}
                                                        // minDate={new Date()}
                                                        onChange={(date)=> this.updateModel('firstPaymentDate', date)}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.firstPaymentDate}</small>}
                                                </div>
                                                <div className="form-group col-4">
                                                    <label>Month End </label>
                                                    <input type="number" className="form-control" placeholder="Enter Month End"
                                                           onChange={(e)=> this.updateModel('monthEnd', e.target.value) }
                                                           value={this.state.model.monthEnd}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.monthEnd}</small>}
                                                </div>
                                                <div className="form-group col-4">
                                                    <label>Number of Repayments </label>
                                                    <input type="number" className="form-control" placeholder="Enter Number of Repayments"
                                                        onChange={(e)=> this.updateModel('numberOfRepayments', parseInt(e.target.value)) }
                                                           value={this.state.model.numberOfRepayments}/>
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.numberOfRepayments}</small>}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-6">
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <label>Total Interest</label>
                                                    <input type="number" disabled className="form-control" placeholder="Enter Total Interest"
                                                           value={this.state.model.interest}/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Total Repayment</label>
                                                    <input type="number" disabled className="form-control" placeholder="Enter Total Repayment"
                                                           value={this.state.model.totalRepayment}/>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-6">
                                                    <div>
                                                        <label>Last Repayment Date</label>
                                                    </div>
                                                    <DatePicker
                                                        disabled
                                                        className="form-control"
                                                        placeholderText="Enter Loan Date"
                                                        selected={new Date(this.state.model.lastPaymentDate)}
                                                        // minDate={new Date()}
                                                        onChange={(date)=> this.updateModel('lastPaymentDate', date)}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                    { this.state.errors && <small className="text-danger">{this.state.errors.lastPaymentDate}</small>}
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Tenure <small>(months)</small> </label>
                                                    <input type="number" disabled className="form-control" placeholder="Enter Tenure"
                                                           value={this.state.model.tenure}/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Days till first payment</label>
                                                    <input type="text" disabled className="form-control" placeholder="Enter Last Repayment Date"
                                                           value={this.state.model.daysTillFirstPayment }/>
                                                </div>
                                                <div className="form-group col-6">
                                                    <label>Loan duration <small>(days)</small> </label>
                                                    <input type="number" disabled className="form-control" placeholder="Enter Tenure"
                                                           value={this.state.model.loanDuration}/>
                                                </div>
                                            </div>
                                            <h4>Monthly Installment: <strong>${Math.ceil(this.state.installment)}</strong> </h4>
                                        </div>

                                    </div>
                                    { !this.quote && <Button type='primary'
                                                             loading={this.state.loading}
                                                             disabled={this.state.loading}
                                                             onClick={this.submitLoan}
                                                             >Submit</Button> }
                                    { this.quote &&
                                    <Button type='primary'
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            onClick={()=>{
                                                localStorage.setItem('savedLoan', JSON.stringify(this.state.model));
                                                this.props.history.push('/customers/new');
                                        }}>Save and continue</Button> }
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>

        );
    }
}
