import React from "react";
import {utils} from "../services/utils";
import {BaseComponent} from "../components/basecomponent";


export class Agreement extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loan: null,
            installments: []
        }
    }

    loanId = this.props.match.params.loanId;
    urls = {
        loans: "/loans",
        installments: "/installments/loan/"+this.loanId
    };

    componentDidMount() {
        this.getLoan();
        this.getInstallments();
    }

    getLoan(){
        this.get(this.urls.loans+'/'+this.loanId)
            .then(data => {
                utils.logger(data);
                this.setState({ loan: data.body });
            });
    }

    getInstallments(){
        this.get(this.urls.installments)
            .then(data => {
                utils.logger(data);
                this.setState({ installments: data.body.content });
            });
    }

    render() {
        return (
            <div className="container mb-5">
                <div className="text-center">
                    <img src={process.env.PUBLIC_URL+"/static/img/twenties-logo.png"} alt="" style={{height:150}}/>
                </div>
                { this.state.loan &&
                <span>
                    <table className="w-100 table table-bordered table-sm table-centered">
                        <tbody>
                            <tr>
                                <td>First Names</td>
                                <td>{ this.state.loan.customer.firstName }</td>
                            </tr>
                            <tr>
                                <td>Surnames</td>
                                <td>{ this.state.loan.customer.lastName }</td>
                            </tr>
                            <tr>
                                <td>ID / Passport Number</td>
                                <td>{ this.state.loan.customer.idNumber }</td>
                            </tr>
                            <tr>
                                <td>Employer / Business</td>
                                <td>{ this.state.loan.customer.typeOfBusiness }</td>
                            </tr>
                            <tr>
                                <td>Employer Number / Location</td>
                                <td>{ this.state.loan.customer.pensionNumber }</td>
                            </tr>
                            <tr>
                                <td>Net Pay / Approx profits</td>
                                <td>{ this.state.loan.customer.netMonthlySalary }</td>
                            </tr>
                            <tr>
                                <td>Residential Address</td>
                                <td>{ this.state.loan.customer.address }</td>
                            </tr>
                            <tr>
                                <td>Bank</td>
                                <td>{ this.state.loan.customer.bank && this.state.loan.customer.bank.name }</td>
                            </tr>
                            <tr>
                                <td>Branch</td>
                                <td>{ this.state.loan.customer.bankBranch }</td>
                            </tr>
                            <tr>
                                <td>Account Number</td>
                                <td>{ this.state.loan.customer.bankAccountNumber }</td>
                            </tr>
                        </tbody>
                    </table>
                    <h4>TERMS AND CONDITIONS</h4>
                    <div>
                        <div style={{fontSize:9}}>
                            <div>1.    THE LOAN AMOUNT</div>
                            <div>1.1  Twenties Capital  (Pvt) Ltd agrees to make available the loan and I hereby abide by the following terms and conditions as well as any other conditions as may come into being from time to time.                      </div>
                            <div>1.2 Twenties Capital  shall pay the loan amount by cash or depositing directly into my bank account made available by myself.</div>
                            <div>1.3 I shall acknowledge receipt of the loan amount by signing or by imprinting my right thumb or both on this loan agreement form.  </div>

                            <div>2.   MONTHLY INTEREST RATE</div>
                            <div>2.1 Interest will be { this.state.loan.interestRate } percent per month, accrued daily, charged and capitalised monthly. Monthly installments constituting part principal and interest  shall be paid as shown on the below schedule. Failure by me to pay any amount owed on the due date in terms of this loan agreement, Twenties Capital  shall be entitled to charge interest at the same rate as  when the contract was entered on such amount/ principle borrowed or amount that l have not paid plus a penalty of ten percent of the outstanding amount.</div>
                            
                            <div>3.   PAYMENT</div>
                            <div>3.1 I agree and mandate Twenties Capital  the sole right to deduct monthly installments in full as outlined in the repayment schedule below directly from my bank account details given above or my employer's payroll. I shall complete and sign a stop order form to facilitate the deductions. The deductions will be made on unpaid salaries or any other remuneration credits payable to me and this is an irrevocable instruction which cannot be changed whatsoever during the term of this contract. In the event of death, Twenties Capital  will recover the outstanding balance from my pension, terminal benefits / leave days and other gratuities .                                                                                                                                                                       </div>
                            <div>3.2 In the event that I revoke, cancel and or withdraw from the contract after lodging or making a duly signed application, I authorise Twenties Capital  to charge me a once off application fee of ten United States Dollars or equavalent for adminstration and related costs associated thereof with my application.  </div>
                            <div>3.3 Early repayments are permitted. In such an event, interest will only be charged on existing month/s covered and remaining months interest will be pardoned and a 5% fee will be charged on the outstanding principle.</div>
                            <div>3.4 Should I change employment or business, I must advise Twenties Capital  of the change within seven working days of such an event and undertake to bring letter of comfort from the new employer accompanied with a repayment plan.</div>

                            <div>4.    CONTRACT BREACH </div>
                            <div>4.1  I shall be liable for the payment of all expenses incurred by Twenties Capital  in exercising any right against me in respect of any breach of the contract including all legal charges on the attorney /client scale, debt collection/attorney collection charges, tracing charges, loan principal and interest thereof.</div>
                            <div>4.2  In the event of my failure to pay any amount which is owing on the due date in terms of this agreement in full or any breach of the terms of this agreement or cancel of this agreement, Twenties Capital  will claim and recover any damages suffered.</div>

                            <div>5.   GENERAL</div>
                            <div>5.1 Details of monthly repayments is provided below in this loan agreement.</div>
                            <div>5.2 A statement signed by Twenties Capital  authorised officer addressed to me, at my request shall be made available showing amount loaned, amount repaid, outstanding balance and any other amounts which deem fit to be dispayed. </div>
                            <div>5.3 Twenties Capital  shall be entitled to cede and delegate respectively any rights and obligations under this agreement to any third part whatsoever.</div>
                            <div>5.4 I authorise Twenties Capital  to access any information available to my application, and also give Twenties Capital  permission to register details of the conduct of my account with any credit bureau and I waive any claims I may have against Twenties Capital  in respect of such disclosure.</div>
                            <div>5.5 I choose domicilium citandi et executandi at the residential address shown above and hereby submit myself to the jurisdiction of the courts of Zimbabwe in any dispute arising from this agreement, notwithstanding that the time that proceedings are commenced I am domiciled outside Zimbabwe </div>
                        </div>
                    </div>
                    <h4 className="mt-2">LOAN REPAYMENT SCHEDULE</h4>
                    { this.state.installments &&
                    <table className="w-50 table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th>Month</th>
                                <th>Installment Date</th>
                                <th>Installment Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            { this.state.installments.map((v, i) => {
                            return (
                            <tr key={i}>
                                <td>{i+1}</td>
                                <td>{new Date(v.date).toDateString()}</td>
                                <td>{v.total}</td>
                            </tr>
                            )}) }
                        </tbody>
                    </table> }
                    <div className="mt-2 strong">
                        SIGNED BEFORE ME  AT …………..………............... THIS: <strong style={{textTransform:"uppercase"}}>{new Date(this.state.loan.loanDate).toDateString()}</strong>
                    </div>
                    <div className="row mt-2">
                        <div className="col-6">
                            <table className="table table-sm">
                                <tbody>
                                    <tr>
                                        <td>Loan Amount</td>
                                        <td>{this.state.loan.principal}</td>
                                    </tr>
                                    <tr>
                                        <td>Loan Period</td>
                                        <td>{this.state.loan.loanDuration} days</td>
                                    </tr>
                                    <tr>
                                        <td>Monthly Installment</td>
                                        <td>{this.state.installments[0] ? this.state.installments[0].total : "-"}</td>
                                    </tr>
                                    <tr>
                                        <td>First Installment Date</td>
                                        <td>{ new Date(this.state.loan.firstPaymentDate).toDateString() }</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-6">
                            <div className="border text-center align-contents-center"
                                 style={{width:'75%', height: '75%'}}>
                                RIGHT THUMB
                            </div>
                        </div>

                        <div className="col-6 text-center">
                            .............................................................. <br/>
                            Witness (Loan Officer)
                        </div>
                        <div className="col-6 text-center">
                            .............................................................. <br/>
                            Client's Signature
                        </div>

                        <div className="col-6">
                            <div>Loan Supervisor:</div>
                            <div>Approved / Declined</div>
                            <div>Reasons:</div>
                        </div>
                        <div className="col-6" style={{height:225}}>
                            <div className="border text-center align-contents-center"
                                 style={{width:'75%', height: '50%'}}>
                                EMPLOYER'S STAMP
                            </div>
                        </div>

                        <div className="col-6 text-center">
                            .............................................................. <br/>
                            For And On Behalf of Twenties Capital (Pvt) Ltd 
                        </div>
                        <div className="col-6 text-center">
                            .............................................................. <br/>
                            Employer's Signature
                        </div>
                    </div>
                </span>
                }
            </div>
        );
    }
}
