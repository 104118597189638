import React from 'react';
import Modal from "react-awesome-modal";
import { utils } from "../../services/utils";
import { BaseComponent } from "../../components/basecomponent";
import { BreadCrumb } from "../../components/breadcrumb";
import moment from "moment";
import { Button, Timeline } from "antd";
import { DataTable } from "../../components/datatable";
import { LoansDatatable } from "../loans/partials/loansdatatable";

export class ViewCustomer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                amount: 0,
                narration: '',
                loanId: null,
                type: "CREDIT"
            },
            customer: {},
            loading: false,
            payments: [],
            urlParams: {
                sort: 'id,asc',
                size: 10,
            },
            loans: [],
            auditEvents: [],
            makePayment: false,
            currentPage: 0
        };
    }

    id = this.props.match.params.id;

    urls = {
        payments: "/payments",
        newPayment: "/payments/new",
        customer: "/customers/" + this.id,
        loans: "/loans?size=50&customer.id=EQ," + this.id + ",AND",
        auditEvents: "/auditevents"
    };

    componentDidMount() {
        if (this.id)
            this.getCustomer();
        this.getLoans();
    }

    getLoans = () => {
        this.get(this.urls.loans)
            .then(res => {
                if (!res) return;
                this.updateModel({ name: 'loanId', value: res.body.content[0] ? res.body.content[0].id : null });
                this.setState({ loans: res.body.content });
            });
    }

    getPayments = () => {
        this.get(this.buildUrl(this.urls.payments))
            .then(res => {
                if (!res) return;
                this.setState({ payments: res.body })
            });
    }

    getCustomer = () => {
        this.get(this.buildUrl(this.urls.customer))
            .then(res => {
                if (!res) return;
                this.setState({ customer: res.body });
                this.addUrlParams({ 'loan.customer.id': 'EQ,' + this.id + ',AND' })
                    .then(() => this.getPayments());
                this.getAuditEvents(this.state.customer.uid);
            });
    }

    clearModel() {
        this.setState({
            model: {
                amount: 0,
                narration: '',
                loanId: this.state.model.loanId,
                type: "CREDIT"
            }
        });
    }

    recordPayment = (e) => {
        e.preventDefault();
        utils.logger(this.state.model);
        this.toggleModal("makePayment");
        this.action(this.urls.newPayment, this.state.model, "Payment saved successfully")
            .then(data => {
                if (!data) return;
                this.clearModel();
                this.getPayments();
            });
    };

    header = (state) => {
        return (
            <>
                <div className="row table-header">
                    <div className="col">Created</div>
                    <div className="col">Loan</div>
                    <div className="col">Source</div>
                    <div className="col">Narration</div>
                    <div className="col">Type</div>
                    <div className="col">Amount</div>
                </div>
                <hr />
            </>
        )
    };

    row = (row, state) => {
        return (
            <>
                <div className="row">
                    <div className="col">{moment(row.created).format("Y-MM-DD")}</div>
                    <div className="col">ID: {row.loan.id} - REF: {row.loan.reference}</div>
                    <div className="col">{row.source}</div>
                    <div className="col">{row.narration}</div>
                    <div className="col">{row.type}</div>
                    <div className="col">{row.amount}</div>
                </div>
                <hr />
            </>
        )
    };

    pagination = (state) => {
        return <div className="row">
            <div className="col">Balance</div>
            <div className="col" />
            <div className="col" />
            <div className="col" />
            <div className="col" />
            <div className="col">
                {this.getBalance()}
            </div>
            <div className="col-12">
                <hr />
                {super.pagination(this.state.payments, this.getPayments)}
            </div>
        </div>
    };

    getBalance() {
        if (!this.state.payments) return;
        let debits = 0;
        let credits = 0;
        this.state.payments.content
            .filter(item => item.type === "DEBIT")
            .forEach(item => debits = debits + parseInt(item.amount));
        this.state.payments.content
            .filter(item => item.type === "CREDIT")
            .forEach(item => credits = credits + parseInt(item.amount));
        return credits - debits;
    }

    render() {
        return (
            <div>
                <BreadCrumb
                    crumbs={[
                        { label: 'Customers', link: '/customers/list' },
                        { label: 'View Customer', link: '#' },
                    ]}
                />
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-9 py-5">
                        <div className={`ui segment border-0 px-0 mx-0` + (this.state.loading ? ' loading' : '')} style={{ boxShadow: 'none' }}>
                            <div className="row">
                                <div className="col-9">
                                    <div className="jumbotron py-4 px3" style={{ background: '#f5f5f5', border: 'solid 1px #cfcfcf' }}>
                                        <div className="h4">Customer Details</div>
                                        <div className="h6" style={{ color: 'grey' }}>
                                            <span className="mr-2"><span className="font-weight-bold">Created:</span> {moment(this.state.customer.created).format("Y-MM-DD HH:MM:ss")}</span>
                                            {/*<span className="mr-2"><span className="font-weight-bold">Status:</span> <Tag color='blue'>{this.state.customer.status}</Tag></span>*/}
                                            <span className="mr-2"><span className="font-weight-bold">ID:</span> {this.state.customer.id}</span>
                                        </div>
                                        <hr className="my-4" />
                                        {/*SEGMENTS*/}
                                        <div className="ui segments basic">
                                            <div className="ui segment py-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>First Name: {this.state.customer.firstName}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Last Name: {this.state.customer.lastName}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ui segment py-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Customer Type: {this.state.customer.customerType}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Mobile Phone: {this.state.customer.mobilePhone}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ui segment py-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Bank: {this.state.customer.bank && this.state.customer.bank.name}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Bank Account Number: {this.state.customer.bankAccountNumber}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ui segment py-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Pension Number: {this.state.customer.pensionNumber}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Marital Status: {this.state.customer.maritalStatus}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ui segment py-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <p>Gross Monthly Salary: {this.state.customer.grossMonthlySalary}</p>
                                                    </div>
                                                    <div className="col-6">
                                                        <p>Net Monthly Salary: {this.state.customer.netMonthlySalary}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Button className="mr-2"
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            onClick={() => this.props.history.push('/customers/change/' + this.id)}>
                                            Edit
                                        </Button>
                                        <Button type="primary" className=""
                                            loading={this.state.loading}
                                            disabled={this.state.loading}
                                            onClick={() => {
                                                this.toggleModal("makePayment");
                                            }}
                                        >
                                            Record Payment
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card">
                                        <div className="card-body overflow-auto" style={{ maxHeight: 350 }}>
                                            <h2>Activity</h2>
                                            <hr />
                                            <div className={`ui ` + (this.state.loading && `active inline centered loader`)} />
                                            <Timeline>
                                                {this.state.auditEvents.map((event, i) => {
                                                    return <Timeline.Item key={i}>
                                                        {this.getAuditMessage(event)}
                                                    </Timeline.Item>
                                                })}
                                            </Timeline>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {this.state.customer.fileUploads &&
                            <>
                                <h3>Uploaded customer documents</h3>
                                <div className="form-group mb-5">
                                    {
                                        this.state.customer.fileUploads.map((file) => {
                                            return <div key={file.id}><a href={process.env.REACT_APP_API_URL +
                                                "/files/download/file/" + file.id + "?access_token=" +
                                                localStorage.getItem("token")}>{file.name}</a></div>;
                                        })
                                    }
                                </div>
                            </>
                        }


                        <h3>Customer Statement</h3>
                        <hr />
                        <div className={`ui ` + (this.state.loading && `active inline centered loader`)} />
                        <DataTable
                            {...this.props}
                            options={{
                                data: this.state.payments ? this.state.payments.content : [],
                                header: this.header,
                                row: this.row,
                                pagination: this.pagination,
                                search: {
                                    onSearch: (searchString) => {
                                        if (!searchString) return;
                                        this.addUrlParams({
                                            "page": 0,
                                            "narration": "EQ," + searchString + ",AND",
                                        }).then(() => this.getPayments());
                                    }
                                },
                                filters: {
                                    display: this.state.urlParams,
                                    fields: [
                                        { label: "Size", name: "size", type: "select", options: ["10", "20", "50", "100"] },
                                        { label: "Type", name: "type", type: "select", options: ["ALL", "DEBIT", "CREDIT"] },
                                        { label: "Source", name: "source", type: "select", options: ["ALL", "TELLER", "SSB"] },
                                        { label: "Loan ID", name: "loan.id", type: "input" },
                                        { label: "Loan REF", name: "reference", type: "input" },
                                    ],
                                    onAdd: (name, value) => {
                                        if (!value) return;
                                        let params = utils.copy(this.state.urlParams);
                                        if (value === 'ALL') {
                                            delete params[name];
                                            this.deleteUrlParams([name]);
                                        } else {
                                            params[name] = "EQ," + value + ",AND";
                                        }
                                        params.page = 0;
                                        this.addUrlParams(params)
                                            .then(() => this.getPayments());
                                    },
                                    onRemove: (name) => {
                                        let params = this.state.urlParams;
                                        params = delete params[name];
                                        this.addUrlParams(params)
                                            .then(() => this.getPayments());
                                    },
                                }
                            }}
                        />

                        <div className="mt-5">
                            <h3>Customer Loans</h3>
                            <hr />
                            <LoansDatatable
                                {...this.props}
                                customerId={this.state.customer ? this.state.customer.id : null}
                            />
                        </div>

                    </div>
                </div>


                {/* MAKE PAYMENT MODAL */}
                <Modal visible={this.state.makePayment} width="400"
                    effect="fadeInUp" onClickAway={() => this.toggleModal("makePayment")}>
                    <div className="segment px-3 py-4">
                        <form className="form-group" onSubmit={(e) => this.recordPayment(e)}>
                            <label>Enter Payment Details</label><br />
                            <select
                                name="type"
                                className="form-control mb-2 w-50"
                                style={{ width: 120 }}
                                value={this.state.model.type}
                                onChange={(e) => this.updateModel(e.target)}>
                                <option value="DEBIT">DEBIT</option>
                                <option value="CREDIT">CREDIT</option>
                            </select>
                            <select
                                name="loanId"
                                className="form-control mb-2 w-50"
                                style={{ width: 120 }}
                                onChange={(e) => this.updateModel(e.target)}>
                                {this.state.loans.map((loan, i) => {
                                    return <option key={i} value={loan.id}>ID: {loan.id} - ${loan.totalRepayment}</option>;
                                })}
                            </select>
                            <input type="text"
                                name="narration"
                                placeholder="Payment Narration"
                                className="form-control mb-2"
                                value={this.state.model.narration}
                                onChange={(e) => this.updateModel(e.target)} />
                            <input type="number"
                                name="amount"
                                placeholder="Repayment Amount"
                                className="form-control mb-2"
                                value={this.state.model.amount}
                                onChange={(e) => this.updateModel(e.target)} />
                            <button className="btn btn-primary"
                                onClick={(e) => this.recordPayment(e)}>
                                Submit
                            </button>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}
