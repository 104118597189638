import React from 'react';
import {BreadCrumb} from "../../components/breadcrumb";
import moment from "moment";
import {Button, Tag, Timeline} from "antd";
import {BaseComponent} from "../../components/basecomponent";
import {DataTable} from "../../components/datatable";
import {utils} from "../../services/utils";

export class ViewFile extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            auditEvents: [],
            records: [],
            urlParams: {
                size: 10,
                sort: 'id,asc',
                'fileUpload.id': 'EQ,'+this.id+',AND'
            },
            currentPage: 0
        }
    }

    id = this.props.match.params.id;
    urls = {
        fileDownload: '/files/download/file/{fileId}',
        file: '/files/{fileId}',
        auditEvents: "/auditevents",
        fileRecords: '/files/records',
        action: '/files/action/{action}/{id}'
    }

    componentDidMount() {
        this.getFile();
        this.getRecords();
    }

    change(action, id) {
        this.get(
            this.urls.action
                .replace("{action}", action)
                .replace("{id}", id))
            .then(data => {
                if(!data) return;
                this.componentDidMount();
            });
    }

    getRecords = () => {
        this.get(this.buildUrl(this.urls.fileRecords))
            .then(res => {
                if(!res) return;
                this.setState({ records: res.body });
            })
    }

    getFile(){
        this.get(this.urls.file.replace('{fileId}', this.id))
            .then(res => {
                if(!res) return;
                this.setState({ file: res.body });
                this.getAuditEvents(res.body.uid);
            });
    }

    header = (state) => {
        return (
            <>
                <div className="row table-header">
                    <div className="col">Record</div>
                    <div className="col">Customer</div>
                    <div className="col">Amounts</div>
                </div>
                <hr/>
            </>
        )
    };

    row = (row, state) => {
        return (
            <>
                <div className="row">
                    <div className="col">
                        <strong>ID: </strong>{row.id} <br/>
                        <strong>Status: </strong><Tag>{row.status}</Tag> <br/>
                        <strong>Message: </strong>{row.message}
                    </div>
                    <div className="col">
                        <strong>ID No: </strong>{row.idNumber} <br/>
                        <strong>Name: </strong>{row.firstNames} {row.lastName}<br/>
                        <strong>Paid: </strong>{row.amount} <br/>
                    </div>
                </div>
                <hr/>
            </>
        )
    };

    pagination = () => {
        return super.pagination(this.state.records, this.getRecords);
    }

    render() {
        return (
            <div>
                <BreadCrumb
                    crumbs={[
                        {label: 'Files', link: '/ssb'},
                        {label: 'View File', link: '#'},
                    ]}
                />
                <div className="row mx-0 d-flex align-items-start justify-content-center">
                    <div className="col-9 py-5">
                        <div className={`ui segment border-0 px-0 mx-0` + (this.state.loading ? ' loading' : '')} style={{boxShadow: 'none'}}>
                            <div className="row">
                                <div className="col-9">
                                    <div className="jumbotron py-4 px3" style={{background: '#f5f5f5', border:'solid 1px #cfcfcf'}}>
                                        <div className="h4">File Details</div>
                                        <div className="h6" style={{color:'grey'}}>
                                            <span className="mr-2"><span className="font-weight-bold">Created:</span> {moment(this.state.file.created).format("Y-MM-DD HH:MM:ss")}</span>
                                            <span className="mr-2">
                                                <span className="font-weight-bold">Status: </span>
                                                { !this.state.file.rejected && this.state.file.authorized && <Tag color="green" className="mr-2">Authorized</Tag> }
                                                { !this.state.file.rejected && !this.state.file.authorized && <Tag color="orange" className="mr-2">Pending</Tag>}
                                                { this.state.file.rejected && <Tag color="red" className="mr-2">Rejected</Tag>}
                                            </span>
                                            <span className="mr-2"><span className="font-weight-bold">ID:</span> {this.state.file.id}</span>
                                        </div>
                                        <hr className="my-4"/>
                                        <div className="ui segments basic">
                                            <div className="ui segment py-2">
                                                <p>File name: {this.state.file.name}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Upload Status: {this.state.file.status}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Number of Records: {this.state.file.records}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Total record amount: {this.state.file.amount}</p>
                                            </div>
                                        </div>
                                        {!this.state.file.rejected && !this.state.file.authorized &&
                                        <Button type="primary" className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.change("authorize", this.id)}>
                                            Authorize
                                        </Button> }
                                        {!this.state.file.rejected && !this.state.file.authorized &&
                                        <Button type="primary" danger className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.change("reject", this.id)}>
                                            Reject
                                        </Button> }
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card">
                                        <div className="card-body overflow-auto" style={{maxHeight: 350}}>
                                            <h2>Activity</h2>
                                            <hr/>
                                            <div className={`ui ` + (this.state.loading && `active inline centered loader`)}/>
                                            <Timeline>
                                                {this.state.auditEvents.map((event, i)=>{
                                                    return <Timeline.Item key={i}>
                                                        {this.getAuditMessage(event)}
                                                    </Timeline.Item>
                                                })}
                                            </Timeline>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h3>File Records</h3>
                            <hr/>
                            {/*<div className={`ui ` + (this.state.loading && `active inline centered loader`)}/>*/}
                            <DataTable
                                {...this.props}
                                options={{
                                    data: this.state.records?this.state.records.content:[],
                                    header: this.header,
                                    row: this.row,
                                    pagination: this.pagination,
                                    search: {
                                        onSearch: (searchString) => {
                                            if(!searchString) return;
                                            this.addUrlParams({
                                                "page": 0,
                                                "narration": "EQ,"+searchString+",AND",
                                            }).then(()=>this.getRecords());
                                        }
                                    },
                                    filters: {
                                        display: this.state.urlParams,
                                        fields: [
                                            { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                                            { label: "Customer Name", name: "firstNames", type: "input" },
                                            { label: "Loan REF", name: "reference", type: "input" },
                                        ],
                                        onAdd: (name, value)=>{
                                            if(!value) return;
                                            let params = utils.copy(this.state.urlParams);
                                            if(value==='ALL') {
                                                delete params[name];
                                                this.deleteUrlParams([name]);
                                            }else{
                                                params[name] = "EQ,"+value+",AND";
                                            }
                                            params.page = 0;
                                            this.addUrlParams(params)
                                                .then(()=>this.getRecords());
                                        },
                                        onRemove: (name) => {
                                            let params = this.state.urlParams;
                                            params = delete params[name];
                                            this.addUrlParams(params)
                                                .then(()=>this.getRecords());
                                        },
                                    }
                                }}
                            />


                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
