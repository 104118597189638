import * as React from "react";
import {https} from "../services/https";
import {utils} from "../services/utils";
import {Footer} from "./footer";


export class Page extends  React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 0,
            records: [],
            search: '',
            urlParams: {
                sort: 'id,desc',
                size: 10
            },
            domain: {
                first: true,
                last: true,
                numberOfElements: 0,
                number: 0,
                totalPages: 0
            }
        };
    }

    fields = this.props.searchFields.split(",");

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props){
            utils.logger("prop params", this.props.urlParams);
            this.setState({
                urlParams: this.props.urlParams ? this.props.urlParams : this.state.urlParams,
                domain: this.props.domain ? this.props.domain : this.state.domain
            });
        }
    }

    async getMoreRecords(){
        for(let f in this.fields){
            let field = this.fields[f];
            await this.addUrlParams({[field]: `LK,${this.state.search},OR`});
        }
        https.get(this.buildUrl(this.props.uri))
            .then(res => {
                utils.logger("resp", res);
                this.props.response(res);
            }).catch(e => utils.logger(e));
    }

    buildUrl(url){
        // utils.logger("build base", url);
        let base = url + "?";
        let params = this.state.urlParams;
        let count = 0;
        for(let i in params){
            // the following line doesnt run the first time
            if(count !== 0) base = base + "&";
            base = base + i + "=" + params[i];
            count++;
        }
        return base;
    }

    /**
     * @param params an object of key value pairs representing the params to be serialized
     * ie, {sort: 'id,desc'} will be serialized to sort=id,desc
     * @returns {Promise<null>}
     */
    async addUrlParams(params){
        let urlParams = this.state.urlParams;
        for(let p in params) urlParams[p] = params[p];
        return new Promise((resolve)=>{
            this.setState({urlParams: urlParams}, ()=>{
                resolve();
            });
        });
    }

    render(){
        return ( <div>
            <div className="mb-5">
                <h4 className="header-title mb-4 w-100">
                    {this.props.title}
                    <div className="float-right text-right mb-3">
                        <div className="input-group">
                            <input type="text" className="form-control"
                                   placeholder="Search" value={this.state.search}
                                   onChange={(e) => {
                                       this.setState({search: e.target.value});
                                   }}/>
                            <div className="input-group-append">
                                <button className="btn btn-dark mr-1" type="button"
                                        style={{borderRadius: 2}} onClick={(e) => {
                                    utils.logger('submit');
                                    e.preventDefault();
                                    this.addUrlParams({'page': 0});
                                    this.getMoreRecords();
                                }}>
                                    <i className="fa fa-search"/>
                                </button>
                                <div className="btn btn-warning mr-1"
                                     onClick={() => {
                                         this.setState({ search: '' }, ()=>{
                                             this.addUrlParams({'page': 0});
                                             this.getMoreRecords();
                                         })
                                     }}>
                                    <i className="fa fa-refresh text-white"/>
                                </div>
                                <select className="form-control"
                                    onChange={(e)=>{
                                        this.addUrlParams({'size': e.target.value});
                                        this.getMoreRecords();
                                    }}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </h4>

                {this.props.children}

                { this.state.domain &&
                <span>
                    {!this.state.domain.first && <div className="btn btn-light" onClick={() => {
                        this.setState({currentPage: this.state.currentPage - 1}, () => {
                            this.addUrlParams({ page: this.state.currentPage })
                                .then(()=>{
                                    this.getMoreRecords(this.buildUrl(this.props.uri));
                                });
                        });
                    }}> Previous </div>} &nbsp;
                        {!this.state.domain.last && <div className="btn btn-light" onClick={() => {
                            this.setState({currentPage: this.state.currentPage + 1}, () => {
                                this.addUrlParams({ page: this.state.currentPage })
                                    .then(()=>{
                                        this.getMoreRecords(this.buildUrl(this.props.uri));
                                    });
                            });
                        }}>Next</div>} &nbsp;
                        {(this.state.domain.numberOfElements === 0 ? 0 : this.state.domain.number+1) + ' / ' + this.state.domain.totalPages} pages
                </span> }
                <Footer/>
            </div>
        </div> );
    }
}
