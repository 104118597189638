import React from 'react';
import { utils } from "../../services/utils";
import DatePicker from "react-datepicker/es";
import { Footer } from "../../components/footer";
import { BaseComponent } from "../../components/basecomponent";
import { BreadCrumb } from "../../components/breadcrumb";
import { Button } from "antd";
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import {https} from "../../services/https";

const { Dragger } = Upload;

export class NewCustomer extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            errors: null,
            model: {
                firstName: "",
                lastName: "",
                idNumber: "",
                mobilePhone: "",
                created: new Date(),
                customerType: "CIVIL SERVANT",
                active: true,
                dob: new Date(),
                gender: "MALE",
                maritalStatus: "SINGLE",
                pensionNumber: "",
                typeOfBusiness: "",
                // branch: "",
                businessPhone: "",
                address: "",
                grossMonthlySalary: "",
                netMonthlySalary: "",
                accountNumber: "",
                bank: "",
                bankBranch: "",
                bankAccountNumber: "",
                fileUploads: []
            },
            files: [],
            types: [
                'CIVIL SERVANT',
                'PENSIONER',
            ],
            maritalStatus: [
                'SINGLE',
                'MARRIED',
                'DIVORCED',
                'WIDOWED'
            ],
            genders: [
                'MALE',
                'FEMALE'
            ],
            banks: [],
            status: 'ACTIVE'
        }
    }

    id = this.props.match.params.id;
    urls = {
        customers: '/customers',
        editCustomer: '/customers/edit',
        banks: "/banks",
        files: "/customers/files",
        download: "/files/download/file"
    };

    componentDidMount() {
        utils.logger(this.id);
        if (this.id) this.getCustomer();
        this.getBanks();
    }

    getCustomer() {
        this.get(this.urls.customers + "/" + this.id)
            .then(data => {
                if (!data) return;
                data.body.dob = new Date(data.body.dob);
                // data.body.bank = data.body.bank;
                // utils.logger(["<", data, data._links.bank.href, data.bank]);
                data.body.bank = data.body.bank ? data.body.bank.id : "";
                this.setState({ model: data.body })
            });
    }

    getBanks() {
        this.get(this.urls.banks)
            .then(data => {
                if (!data) return;
                if (!this.id)
                    this.updateModel('bank', data.body.content[0] ? data.body.content[0].id : "");
                this.setState({
                    banks: data.body.content,
                })
            })
    }

    submit = async (e) => {
        e.preventDefault();

        if(this.state.files.length === 0){
            this.setState({ errors: { nonFieldErrors: "Please upload at least one file" } });
            return;
        }

        let model = JSON.parse(JSON.stringify(this.state.model));
        model.bank = this.state.banks.find(bank =>
            bank.id === parseInt(this.state.model.bank));
        utils.logger(model);

        let url = (!this.id) ? this.urls.customers : this.urls.editCustomer;
        this.action(url, model, "Submitted successfully", null, false)
            .then(async data => {
                if (!data) return;

                // upload customer files
                await this.beginUpload(data.body.id);

                if (localStorage.getItem('savedLoan')) {
                    this.props.history.push("/loans/new");
                    return;
                }
                this.props.history.push("/customers/list");
            })
    };

    updateModel(field, value) {
        return super.updateModel({ name: field, value: value });
    }

    async beginUpload(customerId) {
        const { files } = this.state;
        const formData = new FormData();
        files.forEach(file => {
            formData.append('file', file);
        });

        this.toggle(true);
        return https.postFile(this.urls.files + "/" + customerId, formData)
            .then(res => {
                utils.logger("action", res);
                this.toggle(false);
                this.handlePostResponse(res, true, null, "Files uploaded successfully")
                    .then((data) => {
                        if (!data) return;
                        this.setState({ files: [] });
                        this.toggle(false);
                    })
            });
    }

    render() {
        return (
            <div>
                <BreadCrumb
                    crumbs={[
                        { label: 'Customers', link: '/customers/list' },
                        { label: this.id ? 'Edit Customer' : 'New Customer', link: '#' },
                    ]}
                />
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-9 py-5">
                        <div className="">
                            <div className="ml-5">
                                <h3 className="mb-4">New Customer Form</h3>
                                <hr />
                                <form onSubmit={(e) => this.submit(e)}>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">First Name</label>
                                                <input type="text" className="form-control"
                                                    placeholder="Enter First Name"
                                                    value={this.state.model.firstName}
                                                    onChange={(e) => this.updateModel('firstName', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.firstName}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Last Name</label>
                                                <input type="text" className="form-control" placeholder="Enter Last Name"
                                                    value={this.state.model.lastName}
                                                    onChange={(e) => this.updateModel('lastName', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.lastName}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">ID Number</label>
                                                <input type="text" className="form-control" placeholder="Enter ID Number"
                                                    value={this.state.model.idNumber}
                                                    onChange={(e) => this.updateModel('idNumber', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.idNumber}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">
                                                    Mobile Phone <small>eg. 263771234567</small>
                                                </label>
                                                <input type="text" className="form-control" placeholder="Enter Mobile Phone"
                                                    value={this.state.model.mobilePhone}
                                                    onChange={(e) => this.updateModel('mobilePhone', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.mobilePhone}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Customer Type</label>
                                                <select className="form-control" placeholder="Enter Customer Type"
                                                    value={this.state.model.customerType}
                                                    onChange={(e) => this.updateModel('customerType', e.target.value)}>
                                                    {this.state.types.map((v, i) => {
                                                        return <option key={i} value={v}>{v}</option>;
                                                    })}
                                                </select>
                                                {this.state.errors && <small className="text-danger">{this.state.errors.customerType}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Date of birth</label><br />
                                                <DatePicker
                                                    className="form-control"
                                                    placeholderText="Enter DOB"
                                                    selected={this.state.model.dob}
                                                    maxDate={new Date()}
                                                    onChange={(date) => this.updateModel('dob', date)}
                                                    dateFormat="yyyy/MM/dd"
                                                /> <br />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.dob}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Type of Business </label>
                                                <input type="text" className="form-control" placeholder="Enter Type of Business"
                                                    value={this.state.model.typeOfBusiness}
                                                    onChange={(e) => this.updateModel('typeOfBusiness', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.typeOfBusiness}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Gender</label>
                                                <select className="form-control"
                                                    onChange={(e) => this.updateModel('gender', e.target.value)}
                                                    value={this.state.model.gender}>
                                                    {this.state.genders.map((v, i) => {
                                                        return <option key={i} value={v}>{v}</option>
                                                    })}
                                                </select>
                                                {this.state.errors && <small className="text-danger">{this.state.errors.gender}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Marital status</label>
                                                <select className="form-control"
                                                    onChange={(e) => this.updateModel('maritalStatus', e.target.value)}
                                                    value={this.state.model.maritalStatus}>
                                                    {this.state.maritalStatus.map((v, i) => {
                                                        return <option key={i} value={v}>{v}</option>
                                                    })}
                                                </select>
                                                {this.state.errors && <small className="text-danger">{this.state.errors.maritalStatus}</small>}
                                            </div>
                                            <div className="form-group">
                                            
                                            </div>
                                            <div className="form-group">
                                                {this.state.model.fileUploads && 
                                                <>
                                                <label>Uploaded customer documents</label>                                                
                                                <div className="form-group">
                                                    {
                                                        this.state.model.fileUploads.map((file) => {
                                                            return <div key={file.id}><a href={process.env.REACT_APP_API_URL + 
                                                                "/files/download/file/" + file.id + "?access_token=" + 
                                                                localStorage.getItem("token")}>{file.name}</a></div>; 
                                                        })
                                                    }
                                                </div>
                                                </>
                                                }
                                                <label>Upload customer documents</label>
                                                <Dragger
                                                    name="file"
                                                    multiple={true}
                                                    onRemove={(file) => {
                                                        this.setState(state => {
                                                            const index = state.files.indexOf(file);
                                                            const newFileList = state.files.slice();
                                                            newFileList.splice(index, 1);
                                                            return {
                                                                files: newFileList,
                                                            };
                                                        });
                                                    }}
                                                    beforeUpload={(file) => {
                                                        this.setState(state => ({
                                                            files: [...state.files, file],
                                                        }));
                                                        return false;
                                                    }}>
                                                    <p className="ant-upload-drag-icon">
                                                        <InboxOutlined />
                                                    </p>
                                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                                    <p className="ant-upload-hint">
                                                        Support for a single or bulk upload.
                                                    </p>
                                                </Dragger>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label>Pension Number </label>
                                                <input type="text" className="form-control" placeholder="Enter Pension Number"
                                                    value={this.state.model.pensionNumber}
                                                    onChange={(e) => this.updateModel('pensionNumber', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.pensionNumber}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Address</label>
                                                <textarea className="form-control" placeholder="Enter Address"
                                                    value={this.state.model.address}
                                                    onChange={(e) => this.updateModel('address', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.address}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Business Phone <small>eg. 263771234567</small></label>
                                                <input type="text" className="form-control" placeholder="Enter Business Phone"
                                                    value={this.state.model.businessPhone}
                                                    onChange={(e) => this.updateModel('businessPhone', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.businessPhone}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Gross Monthly Salary <small>in dollars, omit the dollar sign</small></label>
                                                <input type="number" className="form-control" placeholder="Enter Gross Monthly Salary"
                                                    value={this.state.model.grossMonthlySalary}
                                                    onChange={(e) => this.updateModel('grossMonthlySalary', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.grossMonthlySalary}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Net Monthly Salary <small>in dollars, omit the dollar sign</small></label>
                                                <input type="number" className="form-control" placeholder="Enter Net Monthly Salary"
                                                    value={this.state.model.netMonthlySalary}
                                                    onChange={(e) => this.updateModel('netMonthlySalary', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.netMonthlySalary}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Bank</label>
                                                <select className="form-control"
                                                    onChange={(e) => this.updateModel('bank', e.target.value)}
                                                    value={this.state.model.bank}>
                                                    {this.state.banks.map((v, i) => {
                                                        return <option key={i} value={v.id}>{v.name}</option>
                                                    })}
                                                </select>
                                                {this.state.errors && <small className="text-danger">{this.state.errors.bank}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Bank Branch</label>
                                                <input type="text" className="form-control" placeholder="Enter Bank Branch"
                                                    value={this.state.model.bankBranch}
                                                    onChange={(e) => this.updateModel('bankBranch', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.bankBranch}</small>}
                                            </div>
                                            <div className="form-group">
                                                <label>Bank Account Number</label>
                                                <input type="text" className="form-control" placeholder="Enter Bank Account Number"
                                                    value={this.state.model.bankAccountNumber}
                                                    onChange={(e) => this.updateModel('bankAccountNumber', e.target.value)} />
                                                {this.state.errors && <small className="text-danger">{this.state.errors.bankAccountNumber}</small>}
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            {this.state.errors && <small className="text-danger">{this.state.errors.nonFieldErrors}</small>}
                                        </div>
                                    </div>
                                    <Button type='primary'
                                        loading={this.state.loading}
                                        disabled={this.state.disabled}
                                        onClick={this.submit}
                                    >Submit</Button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />

            </div>);
    }
}
