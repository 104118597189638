import * as React from "react";
import {Link} from "react-router-dom";


export class LeftBar extends React.Component {
    render(){
        return (
            <nav className="nav flex-column mt-5 border-right">
                { this.props.links.map((v, i) => {
                    return <Link key={i} className="nav-link" to={v.path}>{v.title}</Link>;
                }) }
            </nav>
        );
    }
}