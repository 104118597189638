import React from 'react';
import {Button, Checkbox, Input, Select, Tag} from 'antd';
import {utils} from "../services/utils";
import {Notify} from "../services/notifications";
const { Option } = Select;

export class DataTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        header: ()=>{},
        row: ()=>{},
        pagination: ()=>{},
      },
      search: "",
      bulkAction: ""
    };
  }

  options = {
    data: null,
    header: ()=>{},
    row: ()=>{},
    pagination: ()=>{},
    actions: [],
    filters: null,
    search: null
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps !== this.props){
      // utils.logger("props", this.props);
      this.setState({
        options: (this.props.options ? {...this.options, ...this.props.options} : this.options)
      }, ()=>{
        if(this.state.options.actions && this.state.options.actions.length > 0){
          if(!this.state.options.data) return;
          let data = JSON.parse(JSON.stringify(this.state.options.data));
          data.forEach(d => d.chosenAction = this.state.options.actions[0].label);
          let options = this.state.options;
          options.data = data;
          this.setState({
            options: options,
            bulkAction: this.state.options.actions.filter(a=>a.bulk)[0] && this.state.options.actions.filter(a=>a.bulk)[0].label
          })
        }
      });
    }
  }

  onSelect(e, id){
    let data = JSON.parse(JSON.stringify(this.state.options.data));
    let index = data.findIndex(record => record.id === id);
    if(index === -1) return;
    // utils.logger(e.target.checked, id, index);
    data[index].checked = e.target.checked;
    let options = this.state.options;
    options.data = data;
    this.setState({ options: options });
  }

  onSelectAll(e){
    let data = JSON.parse(JSON.stringify(this.state.options.data));
    data.forEach(record => {
      record.checked = e.target.checked;
    });
    let options = this.state.options;
    options.data = data;
    this.setState({ checked: e.target.checked, options: options });
  }

  onChooseAction(value, id){
    let data = JSON.parse(JSON.stringify(this.state.options.data));
    let index = data.findIndex(record => record.id === id);
    if(index === -1) return;
    // utils.logger(e.target.checked, id, index);
    data[index].chosenAction = value;
    let options = this.state.options;
    options.data = data;
    this.setState({ options: options });
  }

  render() {
    return (
      <div className="mb-2">
        <div className="row d-flex align-items-center justify-content-between">
          {this.state.options.filters &&
            <>
            <div className="col-8 text-left">
              {/* FILTERS */}
              {this.state.options.filters.fields.map((v, i)=>{
                return (
                  <span key={i}>
                    {/*SELECT FILTER*/}
                    {v.type === "select" &&
                    <Select
                      size="small"
                      defaultValue={v.options[0]}
                      className="mr-2"
                      name={v.name}
                      onChange={(value)=>{
                        this.state.options.filters.onAdd(v.name, value)}}>
                      {v.options.map((a, b)=>{
                        return <Option key={b} value={a}>{a}</Option>
                      })}
                    </Select>
                    }
                    {/*INPUT FILTER*/}
                    {v.type === "input" &&
                      <>
                        <Input
                          size="small"
                           placeholder={v.label}
                           name={v.name}
                           style={{width:120}}
                           className="mr-1"
                           onChange={(e)=> this.setState({[v.name]: e.target.value})}/>
                        <Button type="default" size="small" className="mr-2"
                                onClick={()=>this.state.options.filters.onAdd(v.name, this.state[v.name])}>Add</Button>
                      </>
                    }
                  </span>
                )
              })}
            </div>
            </>
          }

          {/* SEARCH */}
          {this.state.options.search &&
            <div className="col-4 text-right">
              <form onSubmit={(e)=>{
                e.preventDefault();
                this.state.options.search.onSearch(this.state.search)
              }}>
              <div className="ui action input">
                <input type="text" placeholder="Search..."
                  value={this.state.search}
                  onChange={(e)=> this.setState({search: e.target.value})}
                />
                <button className="ui button"
                  onClick={()=> this.state.options.search.onSearch(this.state.search) }
                  >Search</button>
                {/*<div className="ui button basic mini"*/}
                {/*   onClick={()=>this.state.options.search.onReset()}*/}
                {/*><i className="undo icon mr-0"/></div>*/}
              </div>
              </form>
            </div>
          }

          {/*CLOSEABLE FILTER DISPLAYS TAGS*/}
          {
            this.state.options.filters &&
            this.state.options.filters.display &&
            <>
              <div className="col-12">
                {Object.entries(this.state.options.filters.display).map(([key, value], i)=>{
                  if(key === "page") value++;
                  return (
                    <Tag
                      key={i} closable
                      onClose={() => this.state.options.filters.onRemove(key)}>
                      {key}: {value}
                    </Tag>)
                })}
              </div>
            </>
          }

          {/*BULK ACTIONS*/}
          {
            this.state.options.actions &&
            this.state.options.actions.length > 0 &&
            this.state.bulkAction &&
          <div className="col-12 mt-2">
            <Select size="small"
              defaultValue={this.state.options.actions[0].label}
              value={this.state.bulkAction}
              className="mr-2"
              style={{width:100}}
              onChange={(value)=> this.setState({bulkAction: value})}>
              {this.state.options.actions.map((a, b)=>{
                if(!a.bulk) return null;
                return <Option key={b} value={a.label}>{a.label}</Option>
              })}
            </Select>
            <Button type="default" size="small"
                    onClick={()=>{
                      utils.logger("bulkAction", this.state.bulkAction);
                      let index = this.state.options.actions.findIndex(action => action.label === this.state.bulkAction);
                      if(index === -1)  return;
                      let selectedAction = this.state.options.actions[index];
                      let selectedRecords = this.state.options.data.filter(record => record.checked);
                      Notify.confirm(
                        'Are you sure?',
                        'You\'re about to perform the "'+selectedAction.label+'" action on "'+selectedRecords.length+'" records',
                        'warning',
                        ()=> {
                          selectedRecords.forEach(record => selectedAction.onAction(record));
                          if(this.state.options.postAction) this.state.options.postAction();
                          this.setState({checked: false});
                        }
                      );
                    }}>Apply</Button>
          </div>
          }

        </div>

        {/*TABLE START*/}
        {this.state.options.data &&
          <>
            {/*HEADER*/}
            <div className="mt-3 row">
              {this.state.options.actions.length > 0 &&
              <div className="col-auto">
                <Checkbox
                  checked={this.state.checked}
                  onChange={(e) => this.onSelectAll(e)}/>
              </div> }
              <div className="col">
                {this.state.options.header(this.state)}
              </div>
              {this.state.options.actions.length > 0 &&
              <div className="col-2">
                <strong>Actions</strong>
              </div> }
            </div>
            {/*ROW*/}
            {this.state.options.data &&
              this.state.options.data.map((item, i)=>{
                return <div key={i} className="row">
                  {this.state.options.actions.length > 0 &&
                  <div className="col-auto">
                    <Checkbox
                      checked={item.checked}
                      onChange={(e) => this.onSelect(e, item.id)}/>
                  </div> }
                  <div className="col">
                    {this.state.options.row(item, this.state)}
                  </div>
                  {this.state.options.actions.length > 0 &&
                  <>
                  {this.state.options.actions.length <= 2 &&
                  <div className="col-2">
                    {this.state.options.actions.map((action, i) => {
                      return <Button type="default" key={i} size="small" className="mr-1 mb-1"
                        onClick={()=>{
                          action.onAction(item);
                          if(this.state.options.postAction) this.state.options.postAction();
                        }}>{action.label}</Button>
                    })}
                  </div>}

                  {this.state.options.actions.length > 2 &&
                  <div className="col-2">
                    <Select size="small"
                      defaultValue={this.state.options.actions[0].label}
                      value={item.chosenAction}
                      className="mr-2 mb-2"
                      style={{width:150}}
                      onChange={(value)=> this.onChooseAction(value, item.id)}>
                    {this.state.options.actions.map((a, b)=>{
                      if(typeof a.active === "function") if(a.active(item) === false) return null;
                      return <Option key={b} value={a.label}>{a.label}</Option>
                    })}
                    </Select>
                    <Button type="default" size="small"
                            className="mt-2"
                            onClick={()=>{
                              let index = this.state.options.actions.findIndex(action => action.label === item.chosenAction);
                              this.state.options.actions[index].onAction(item);
                              if(this.state.options.postAction) this.state.options.postAction();
                            }}>Apply</Button>
                  </div>
                  }
                  </>
                  }
                </div>
              })
            }
            {/*PAGINATION*/}
            <div>
              {this.state.options.pagination(this.state)}
            </div>
          </>
        }
      </div>
    );
  }
}
