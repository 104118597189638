import React from 'react';
import {utils} from "../services/utils";
import {https} from "../services/https";
import {Notify} from "../services/notifications";
import moment from "moment";

export class BaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      errors: {},
      urlParams: {
        size: 20,
        page: 1,
        sort:'id,desc'
      }
    }
  }

  getAuditEvents(uid){
    this.get(this.buildUrlWithParams(this.urls.auditEvents, {
      size:10,
      entityUid: "EQ,"+uid+",AND"
    }))
        .then(res => {
          if(!res) return;
          this.setState({ auditEvents: res.body.content });
        });
  }

  getAuditMessage(event){
    switch(event.action){
      case 'CREATE':
        return `${event.action} event by ${event.createdBy} on ${moment(event.created).format("Y-MM-DD hh:mm:ss")}`;
      case 'UPDATE':
        return `${event.modificationType?event.modificationType:event.action} event by ${event.modifiedBy} on ${moment(event.modified).format("Y-MM-DD hh:mm:ss")}`;
      case 'DELETE':
        return `${event.action} event by ${event.modifiedBy} on ${moment(event.modified).format("Y-MM-DD hh:mm:ss")}`;
      default:
        return null;
    }
  }

  toggleModal(modal){
    this.setState({
      [modal]: !this.state[modal]
    });
  }

  mapValue(name, value){
    let sensitive = ["size", "sort"];
    if(sensitive.includes(name))
      return value;
    return "EQ,"+value+",OR";
  }

  pagination(model, execute){
    // utils.logger(model, execute);
    if(!model) return null;
    return(
      <div className="col-12">
        {
          !model.first && <div className="ui button basic compact" onClick={() => {
            this.setState({currentPage: this.state.currentPage - 1}, () => {
              this.addUrlParams({ page: this.state.currentPage })
                .then(()=> execute());
            });
          }}> Previous </div>
        } &nbsp;
        {
          !model.last && <div className="ui button basic compact" onClick={() => {
            this.setState({currentPage: this.state.currentPage + 1}, () => {
              this.addUrlParams({ page: this.state.currentPage })
                .then(()=> execute());
            });
          }}>Next</div>
        } &nbsp;
        {(model.numberOfElements === 0 ? 0 : model.number+1) +
        ' / ' + model.totalPages} pages
      </div>
    );
  }

  toggle(boolean){
    this.setState({ loading: boolean });
  }

  reset(){}

  delete(url, bool=false){
    this.toggle(true);
    return https.delete(url)
      .then(res => {
        utils.logger("get result", res);
        this.toggle(false);
        return this.handleGetResponse(res, bool);
      });
  }

  get(url, bool=false){
    this.toggle(true);
    return https.get(url)
      .then(res => {
        utils.logger("get result", res);
        this.toggle(false);
        return this.handleGetResponse(res, bool);
      });
  }

  getNoAuth(url, bool=false){
    this.toggle(true);
    return https.getNoAuth(url)
      .then(res => {
        utils.logger(res);
        this.toggle(false);
        return this.handleGetResponse(res, bool);
      });
  }

  getNoAuthLoader(url, bool=false){
    return https.getNoAuth(url)
      .then(res => {
        this.toggle(false);
        return this.handleGetResponse(res, bool);
      });
  }

  handleGetResponse(res, bool){
    if(!res) return this.setErrors({err: "Something went wrong, connectivity maybe?"}, bool);
    return res.json()
      .then( r => {utils.logger("get response", r);
        if(res.status === 401 || res.status === 400) return this.setErrors({error: r.error_description}, bool);
        if(res.status !== 200) return this.setErrors(r.errors, bool);
        return r;
      })
      .catch(e => {
        utils.logger(e);
      });
  }

  action(url, payload, message, callback=null, bool=true){
    this.toggle(true);
    this.setState({errors: null});

    // utils.logger(url, payload);
    return https.post(url, payload)
      .then(res => {
        utils.logger("action", res);
        this.toggle(false);
        return this.handlePostResponse(res, bool, callback, message);
      });
  }

  actionNoAuth(url, payload, message, callback, bool=true){
    this.toggle(true);
    this.setState({errors: null});

    utils.logger(url, payload);
    return https.postNoAuth(url, payload)
      .then(res => {
        utils.logger(res);
        this.toggle(false);
        return this.handlePostResponse(res, bool, callback, message);
      });
  }

  handlePostResponse(res, bool, callback, message){
    this.reset();
    if(!res) return this.setErrors({err: "Something went wrong, connectivity maybe?"}, bool);
    return res.json().then( r => {
      utils.logger("post response", r);
      if(res.status === 401) return this.setErrors({error: r.error_description}, bool);
      if(res.status !== 200) return this.setErrors(r.errors, bool);

      if(callback) callback();
      this.setSuccess(bool, message);

      return r;
    })
      .catch(e => {
        utils.logger(e);
      });
  }

  updateModel(target){
    return new Promise((resolve => {
      let model = this.state.model;
      model[target.name] = target.value;
      this.setState({ model: model }, ()=>{
        resolve();
      });
    }))
  }

  setErrors(res, bool){
    // utils.logger("exception", res, bool);
    if(bool){
      for(let e in res){
        utils.logger("exception", e, res[e]);
        Notify.fire(
          'Uh oh!',
          res[e],
          'error'
        )
      }
    }else
      this.setState({errors: res});

    return false;
  }

  setSuccess(bool, message){
    if(bool)
      Notify.fire(
        'Done!',
        message,
        'success'
      )
  }

  buildUrl(url){
    // utils.logger("build base", url);
    let base = url + "?";
    return this.build(base, this.state.urlParams);
  }

  buildUrlWithParams(url, params){
    // utils.logger("build base", url);
    let base = url + "?";
    return this.build(base, params);
  }

  build(base, params){
    let count = 0;
    for(let i in params){
      // first iteration skips this
      if(count !== 0) base = base + "&";
      base = base + i + "=" + encodeURIComponent(params[i]);
      count++;
    }
    return base;
  }

  /**
   * @param params an object of key value pairs representing the params to be serialized
   * ie, {sort: 'id,desc'} will be serialized to sort=id,desc
   * @returns {Promise<null>}
   */
  async addUrlParams(params){
    let urlParams = this.state.urlParams;
    for(let p in params) urlParams[p] = params[p];
    return new Promise((resolve)=>{
      this.setState({urlParams: urlParams}, ()=>{
        resolve();
      });
    });
  }
  async deleteUrlParams(params){
    let urlParams = this.state.urlParams;
    for(let p in params) delete urlParams[params[p]];
    return new Promise((resolve)=>{
      this.setState({urlParams: urlParams}, ()=>{
        resolve();
      });
    });
  }

  render() {
    return null;
  }
}
