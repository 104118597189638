import React from 'react';
import { Form, Input, Button, Transfer } from 'antd';
import {BreadCrumb} from "../../components/breadcrumb";
import {BaseComponent} from "../../components/basecomponent";
import {utils} from "../../services/utils";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

export class NewUser extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      targetPermissions: [],
      selectedPermissions: [],
      permissions: [],
      model: {
        email: "",
        firstName: "",
        lastName: "",
        password: "",
        permissions: []
      }
    }
  }

  userId = this.props.match.params.userId;

  urls = {
    permissions: "/permissions",
    userPermissions: "/users/{userId}/permissions",
    users: "/users",
  };

  componentDidMount() {
    this.getPermissions();
    if(this.userId)
      this.getUserPermissions();
  }

  getPermissions(){
    this.get(this.urls.permissions)
      .then(res => {
        if(!res) return;
        this.setState({ permissions: res.body.content });
      });
  }

  getUserPermissions(){
    this.get(this.urls.userPermissions)
      .then(res => {
        if(!res) return;
        this.setState({ targetPermissions: res.body });
      });
  }

  createUser = values => {
    let model = utils.copy(this.state.model);
    model.permissions = this.state.targetPermissions.map((index, i) => {
      let perm = utils.copy(this.state.permissions[index-1]);
      delete perm.key;
      return perm;
    });
    delete model.remember;
    utils.logger(model);
    this.action(this.urls.users, model, "User created successfully")
      .then(res => {
        if(!res) return;
        this.props.history.push("/users");
      })
  };

  onValuesChange = (changedValues, allValues) => {
    this.setState({ model: {...allValues, ...changedValues} })
  };

  handleChange = (nextTargetKeys, direction, moveKeys) => {
    this.setState({ targetPermissions: nextTargetKeys });
    utils.logger(nextTargetKeys, direction, moveKeys);
  };

  handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    this.setState({ selectedPermissions: [...sourceSelectedKeys, ...targetSelectedKeys] });
  };

  render() {
    return (
      <div>
        <BreadCrumb
          crumbs={[
            {label: 'Users', link: '/users'},
            {label: 'Create User', link: '#'},
          ]}
        />
        <div className="row d-flex-align-items-center justify-content-center mt-5">
          <div className="col-6">
            <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onValuesChange={this.onValuesChange}
              onFinish={this.createUser}>
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'Please input email address!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'Please input first name!' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[{ required: true, message: 'Please input lsat name!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[{ required: true, message: 'Please input password!' }]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit"
                        loading={this.state.loading}
                        disabled={this.state.loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="col-6">
            <Transfer
              dataSource={this.state.permissions}
              rowKey={item => item.id}
              showSearch
              titles={['Available Permissions', 'User Permissions']}
              listStyle={{
                width: 250,
                height: 300,
              }}
              operations={['to right', 'to left']}
              targetKeys={this.state.targetPermissions}
              selectedKeys={this.state.selectedPermissions}
              onChange={this.handleChange}
              onSelectChange={this.handleSelectChange}
              render={item => item.name}/>
          </div>
        </div>
      </div>
    );
  }
}
