import React from 'react';
import {BaseComponent} from "../../../components/basecomponent";
import {utils} from "../../../services/utils";
import moment from "moment";
import {Tag} from "antd";
import {DataTable} from "../../../components/datatable";

export class LoansDatatable extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            loans: {},
            urlParams: {
                sort: "id,desc",
                size: 10
            },
            currentPage: 0,
            customerId: null,
            loading: false,
        }
    }

    urls = {
        loans: "/loans",
        action: "/loans/action/{action}/{id}",
    };

    componentDidMount() {
        if(this.props.customerId===false) this.getLoans();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps !== this.props) {
            this.setState({customerId: this.props.customerId?this.props.customerId:this.state.customerId},
                ()=> {
                    if(this.state.customerId&&!this.state.loading){
                        utils.logger("changes received", this.props.customerId);
                        this.addUrlParams({'customer.id': 'EQ,'+this.state.customerId+',AND'})
                            .then(()=>this.getLoans());
                    }
                })
        }
    }

    getLoans = () => {
        let url = this.buildUrl(this.urls.loans);
        this.get(url)
            .then(data => {
                if(!data) return;
                this.setState({
                    loans: data.body
                })
            }).catch(e=>utils.logger(e));
    };

    editLoan(action, id) {
        this.get(
            this.urls.action
                .replace("{action}", action)
                .replace("{id}", id))
            .then(data => {
                if(!data) return;
                this.getLoans();
            }).catch(e=>utils.logger(e));
    }

    header = (state) => {
        return (
            <>
                <div className="row table-header">
                    <div className="col">Customer</div>
                    <div className="col">Financial</div>
                    <div className="col">Dates</div>
                    <div className="col">Details</div>
                </div>
                <hr/>
            </>
        )
    };

    row = (row, state) => {
        return (
            <>
                <div className="row">
                    <div className="col">
                        { row.customer &&
                        <span>
                        <div> <strong>First Name:</strong> {row.customer.firstName}</div>
                        <div> <strong>Last Name:</strong> {row.customer.lastName}</div>
                        <div> <strong>ID Number:</strong> {row.customer.idNumber}</div>
                    </span>
                        }
                    </div>
                    <div className="col">
                        <div>Principal: <strong>{row.principal}</strong></div>
                        <div>Interest: <strong>{row.interest} @ {row.interestRate}%</strong></div>
                        <div>Repayments: <strong>{row.numberOfRepayments}</strong></div>
                    </div>
                    <div className="col">
                        <div>Loan Date: <strong>{moment(row.loanDate).format("Y-MM-DD")}</strong></div>
                        <div>First Payment Date: <strong>{moment(row.firstPaymentDate).format("Y-MM-DD")}</strong></div>
                        <div>Last Payment Date: <strong>{moment(row.lastPaymentDate).format("Y-MM-DD")}</strong></div>
                    </div>
                    <div className="col">
                        ID: {row.id} <br/>
                        Created: {moment(row.created).format("Y-MM-DD")} <br/>
                        Status: &nbsp;
                        { !row.rejected && row.authorized && <Tag color="green" className="mr-2">Authorized</Tag> }
                        { !row.rejected && !row.authorized && <Tag color="orange" className="mr-2">Pending</Tag>}
                        { row.rejected && <Tag color="red" className="mr-2">Rejected</Tag>}
                    </div>
                </div>
                <hr/>
            </>
        );
    };

    pagination = (state) => {
        return super.pagination(this.state.loans, this.getLoans)
    };

    render() {
        return (
            <div>
                <div className={`ui ` + (this.state.loading && `active inline centered loader`)}/>
                <DataTable
                    {...this.props}
                    options={{
                        data: this.state.loans.content,
                        header: this.header,
                        row: this.row,
                        pagination: this.pagination,
                        actions: [
                            {
                                bulk: false,
                                label: "View Loan",
                                onAction: (target) =>
                                    this.props.history.push(`/loans/view/${target.id}`)
                            },
                            {
                                bulk: false,
                                label: "Edit",
                                active: (target) =>  (!target.rejected && !target.authorized),
                                onAction: (target) => this.props.history.push('/loans/change/' + target.id)
                            },
                            {
                                bulk: true,
                                label: "Authorize",
                                active: (target) => !target.rejected && !target.authorized,
                                onAction: (target)=> {
                                    if(!target.rejected && !target.authorized)
                                        this.editLoan("authorize", target.id)}
                            },
                            {
                                bulk: true,
                                label: "Reject",
                                active: (target) =>  (!target.rejected && !target.authorized),
                                onAction: (target)=> {
                                    if(!target.rejected && !target.authorized)
                                        this.editLoan("reject", target.id)}
                            },
                            {
                                bulk: false,
                                label: "Print",
                                onAction: (target) =>
                                    this.props.history.push(`/loans/agreement/${target.id}`)
                            },
                        ],
                        postAction: this.getLoans,
                        search: {
                            onSearch: (searchString) => {
                                if(!searchString) return;
                                this.addUrlParams({
                                    "page": 0,
                                    "customer.firstName": "EQ,"+searchString+",AND",
                                    "customer.lastName": "EQ,"+searchString+",OR",
                                    "customer.idNumber": "EQ,"+searchString+",OR",
                                }).then(()=>this.getLoans());
                            },
                        },
                        filters: {
                            display: this.state.urlParams,
                            fields: [
                                { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                                { label: "Status", name: "authorized", type: "select", options: [ "All Loans", "Authorized", "Pending", "Rejected" ] },
                            ],
                            onAdd: (name, value)=>{
                                if(!value) return;
                                let params = utils.copy(this.state.urlParams);
                                if(name === 'authorized'){
                                    delete params.authorized;
                                    delete params.rejected;
                                    this.deleteUrlParams(['authorized', 'rejected']);
                                    if(value === "Authorized") params['authorized'] = "EQ,true,AND";
                                    if(value === "Pending") {
                                        params['authorized'] = "EQ,false,AND";
                                        params['rejected'] = "EQ,false,AND";
                                    }
                                    if(value === "Rejected") params['rejected'] = "EQ,true,AND";
                                }else {
                                    params[name] = "LK,"+value+",AND";
                                }
                                params.page = 0;
                                this.addUrlParams(params)
                                    .then(()=>this.getLoans());
                            },
                            onRemove: (name) => {
                                let params = this.state.urlParams;
                                params = delete params[name];
                                this.addUrlParams(params)
                                    .then(()=>this.getLoans());
                            },
                        }
                    }}
                />
            </div>
        );
    }
}
