import React from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {NavBar} from "./components/navigation";
import {Login} from "./pages/login";
import {Customerslist} from "./pages/customers/customerslist";
import {NewCustomer} from "./pages/customers/newcustomer";
import {Accountslist} from "./pages/accountslist";
import {NewAccount} from "./pages/newaccount";
import {Loanslist} from "./pages/loans/loanslist";
import {NewLoan} from "./pages/loans/newloan";
import {Agreement} from "./pages/agreement";
import {ViewLoan} from "./pages/loans/viewloan";
import {Files} from "./pages/files/files";
import {LoansRejected} from "./pages/loans/loansrejected";
import {LoansAuthorized} from "./pages/loans/loansauthorized";
import {Users} from "./pages/users/users";
import {NewUser} from "./pages/users/newuser";
import {ViewCustomer} from "./pages/customers/viewcustomer";
import {ViewFile} from "./pages/files/viewfile";
// import {Error} from "./pages/error";

class App extends React.Component {

    render(){
        return (
            <Router>
              <Switch>
                <Route exact path="/login" component={Login}/>
                <Route exact path="/loans/agreement/:loanId" component={Agreement}/>
                <Route path="/" component={NavBar}/>
                {/*<Route component={Error}/>*/}
              </Switch>
              <Route exact path="/" component={NewLoan}/>
              <Route exact path="/loans/list" component={Loanslist}/>
              <Route exact path="/loans/list/authorized" component={LoansAuthorized}/>
              <Route exact path="/loans/list/rejected" component={LoansRejected}/>
              <Route exact path="/loans/new" component={NewLoan}/>
              <Route exact path="/loans/new/:quote" component={NewLoan}/>
              <Route exact path="/loans/change/:loanId" component={NewLoan}/>
              <Route exact path="/loans/view/:id" component={ViewLoan}/>
              <Route exact path="/customers/list" component={Customerslist}/>
              <Route exact path="/customers/new" component={NewCustomer}/>
              <Route exact path="/customers/view/:id" component={ViewCustomer}/>
              <Route exact path="/customers/change/:id" component={NewCustomer}/>
              <Route exact path="/accounts/list" component={Accountslist}/>
              <Route exact path="/accounts/new" component={NewAccount}/>
                <Route exact path="/ssb" component={Files}/>
                <Route exact path="/files/view/:id" component={ViewFile}/>
              <Route exact path="/users" component={Users}/>
              <Route exact path="/users/new" component={NewUser}/>

            </Router>
        );
    }

}

export default App;
