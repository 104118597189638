import * as React from "react";
import {utils} from "../services/utils";
import {https} from "../services/https";
import {BaseComponent} from "../components/basecomponent";
import {Button, Form, Input} from "antd";

export class Login extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                username: '',
                password: '',
            },
        }
    }

    urls = {
        user: "/user"
    };

    componentDidMount() {
    }

    login(){
        utils.logger(this.state.model);
        this.toggle(true);
        return https.login(this.state.model)
            .then(res => {
                utils.logger("get result", res);
                this.toggle(false);
                return this.handleGetResponse(res, true);
            }).catch(e => utils.logger(e))
    }

    attempt=()=>{
        this.login()
          .then(data => {
              if(!data) return;
              localStorage.setItem('token', data.access_token);
              localStorage.setItem('username', this.state.model.username);

              this.props.history.push('/loans/new/q');
          });
    };

    onValuesChange = (changedValues, allValues) => {
        this.setState({ model: {...allValues, ...changedValues} })
    };

    render(){
        return (
            <div className="row d-flex justify-content-center align-items-center" style={{height: '100vh'}}>
                    <div className="col-lg-2 col-12">
                        <div className="text-center mb-4">
                            <h3>MFC Online</h3>
                            <p>Kindly provide credentials to login</p>
                        </div>
                        <Form
                          name="basic"
                          initialValues={{ remember: true }}
                          onValuesChange={this.onValuesChange}
                          onFinish={this.attempt}
                        >
                            <Form.Item
                              name="username"
                              rules={[{ required: true, message: 'Please input your username!' }]}
                            >
                                <Input placeholder="Username"/>
                            </Form.Item>

                            <Form.Item
                              name="password"
                              rules={[{ required: true, message: 'Please input your password!' }]}
                            >
                                <Input.Password placeholder="Password"/>
                            </Form.Item>

                            <Form.Item>
                                <Button
                                  loading={this.state.loading}
                                  disabled={this.state.loading}
                                  type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
            </div>
        );
    }
}
