import React from 'react';
import {utils} from "../../services/utils";
import moment from "moment";
import {BaseComponent} from "../../components/basecomponent";
import {BreadCrumb} from "../../components/breadcrumb";
import {DataTable} from "../../components/datatable";
import {Button, Tag, Timeline} from "antd";


export class ViewLoan extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            installments: null,
            model: {
                amount: 0,
            },
            makePayment: false,
            payments: false,
            loanDetails: false,
            repayment: {payments:[]},
            loan: {customer: {}},
            paidToDate: 0,
            urlParams: {
                sort: "id,desc",
                size: 10
            },
            auditEvents: []
        }
    }

    id = this.props.match.params.id;

    urls = {
      installments: "/installments/loan/"+this.id,
      loan: "/loans/"+this.id,
      action: "/loans/action/{action}/{id}",
      auditEvents: "/auditevents"
    };

    componentDidMount() {
        this.getInstallments();
        this.getLoan();
    }

    toggleModal(modal){
        this.setState({
            [modal]: !this.state[modal]
        });
    }

    getInstallments=()=>{
        this.get(this.urls.installments)
            .then(data => {
                if(!data) return;
                let installments = data.body.content;
                let paidToDate = 0;
                for(let i in installments){
                    let payments = installments[i].payments;
                    let totalPaid = 0;
                    for(let f in payments){
                        totalPaid = totalPaid + payments[f].amount;
                        paidToDate = totalPaid;
                    }
                }
                data.body.content = installments;
                this.setState({
                    installments: data.body,
                    paidToDate: paidToDate
                });
            });
    };

    getLoan(){
        this.get(this.urls.loan)
            .then(res => {
                if(!res) return;
                this.setState({ loan: res.body });
                this.getAuditEvents(this.state.loan.uid);
            });
    }

    editLoan(action, id) {
      this.get(
        this.urls.action
          .replace("{action}", action)
          .replace("{id}", id))
        .then(data => {
          if(!data) return;
          this.getLoan();
        });
    }

    header = (state) => {
        return (
          <>
              <div className="row table-header">
                  <div className="col">Repayment Date</div>
                  <div className="col">Principal</div>
                  <div className="col">Interest</div>
                  <div className="col">Total</div>
              </div>
              <hr/>
          </>
        )
    };

    row = (row, state) => {
        return (
          <>
          <div className="row">
              <div className="col">{moment(row.date).format("Y-MM-DD")}</div>
              <div className="col">{row.principal}</div>
              <div className="col">{row.interest}</div>
              <div className="col">{row.total}</div>
          </div>
          <hr/>
          </>
        )
    };

    pagination = (state) => {
        return super.pagination(this.state.installments, this.getInstallments)
    };

    render() {
        return (
            <div>
                <BreadCrumb
                  crumbs={[
                      {label: 'Loans', link: '/loans/list'},
                      {label: 'View Loan', link: '#'},
                  ]}
                />
                <div className="row mx-0 d-flex align-items-start justify-content-center">
                    <div className="col-9 py-5">
                        <div className={`ui segment border-0 px-0 mx-0` + (this.state.loading ? ' loading' : '')} style={{boxShadow: 'none'}}>
                            <div className="row">
                                <div className="col-9">
                                    <div className="jumbotron py-4 px3" style={{background: '#f5f5f5', border:'solid 1px #cfcfcf'}}>
                                        <div className="h4">Loan Details</div>
                                        <div className="h6" style={{color:'grey'}}>
                                            <span className="mr-2"><span className="font-weight-bold">Created:</span> {moment(this.state.loan.created).format("Y-MM-DD HH:MM:ss")}</span>
                                            <span className="mr-2">
                                  <span className="font-weight-bold">Status: </span>
                                                { !this.state.loan.rejected && this.state.loan.authorized && <Tag color="green" className="mr-2">Authorized</Tag> }
                                                { !this.state.loan.rejected && !this.state.loan.authorized && <Tag color="orange" className="mr-2">Pending</Tag>}
                                                { this.state.loan.rejected && <Tag color="red" className="mr-2">Rejected</Tag>}
                              </span>
                                            <span className="mr-2"><span className="font-weight-bold">ID:</span> {this.state.loan.id}</span>
                                        </div>
                                        <hr className="my-4"/>
                                        <div className="ui segments basic">
                                            <div className="ui segment py-2">
                                                <p>
                                                    Customer: {this.state.loan.customer.firstName &&
                                                <Button type='link' style={{height:17}} className='p-0'
                                                        onClick={()=>this.props.history.push('/customers/view/'+this.state.loan.customer.id)}
                                                >{this.state.loan.customer.firstName+' '+this.state.loan.customer.lastName}</Button>}
                                                </p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Reference: {this.state.loan.reference}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Loan Date: {moment(this.state.loan.loanDate).format("Y-MM-DD HH:MM:ss")}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Loan amount applied for: {this.state.loan.loanAppliedFor}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Administrative costs: {this.state.loan.administrativeRate}%</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Interest Charged: {this.state.loan.interestRate}%</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Total Repayment: {this.state.loan.totalRepayment}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Paid to date: {this.state.paidToDate}</p>
                                            </div>
                                            <div className="ui segment py-2">
                                                <p>Outstanding to date: {this.state.paidToDate && (this.state.loan.totalRepayment - this.state.paidToDate)}</p>
                                            </div>
                                        </div>
                                        {!this.state.loan.rejected && !this.state.loan.authorized &&
                                        <Button className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.props.history.push('/loans/change/' + this.id)}>
                                            Edit
                                        </Button> }
                                        {!this.state.loan.rejected && !this.state.loan.authorized &&
                                        <Button type="primary" className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.editLoan("authorize", this.id)}>
                                            Authorize
                                        </Button> }
                                        {!this.state.loan.rejected && !this.state.loan.authorized &&
                                        <Button type="primary" danger className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.editLoan("reject", this.id)}>
                                            Reject
                                        </Button> }
                                        <Button className="mr-2"
                                                loading={this.state.loading}
                                                disabled={this.state.loading}
                                                onClick={()=>this.props.history.push(`/loans/agreement/${this.state.loan.id}`)}>
                                            Print
                                        </Button>
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="card">
                                        <div className="card-body overflow-auto" style={{maxHeight: 350}}>
                                            <h2>Activity</h2>
                                            <hr/>
                                            <div className={`ui ` + (this.state.loading && `active inline centered loader`)}/>
                                            <Timeline>
                                                {this.state.auditEvents.map((event, i)=>{
                                                    return <Timeline.Item key={i}>
                                                        {this.getAuditMessage(event)}
                                                    </Timeline.Item>
                                                })}
                                            </Timeline>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={`ui ` + (this.state.loading && `active loader inline centered`)}/>
                        <DataTable
                          {...this.props}
                          options={{
                              data: this.state.installments ?this.state.installments.content:[],
                              header: this.header,
                              row: this.row,
                              pagination: this.pagination,
                              filters: {
                                  display: this.state.urlParams,
                                  fields: [
                                      { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                                  ],
                                  onAdd: (name, value)=>{
                                      if(!value) return;
                                      let params = utils.copy(this.state.urlParams);
                                      params[name] = "LK,"+value+",AND";
                                      params.page = 0;
                                      this.addUrlParams(params)
                                          .then(()=>this.getInstallments());
                                  },
                                  onRemove: (name) => {
                                      let params = this.state.urlParams;
                                      params = delete params[name];
                                      this.addUrlParams(params)
                                        .then(()=>this.getInstallments());
                                  },
                              }
                          }}
                        />

                    </div>
                </div>


            </div>
        );
    }
};
