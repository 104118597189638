import {utils} from "./utils";


export class https {
    // static serverUrl = "http://localhost:9000";
    // static serverUrl = "https://java.directconnect.co.zw";
    static serverUrl = process.env.REACT_APP_API_URL;

    static postFile(url, data){
        utils.logger(url, data);
        return fetch(`${https.serverUrl+url}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            body: data
        })
        // .then(res => res.json())
          .catch(err => utils.logger(err))
    }

    static postNoAuth(url, data){
        utils.logger(url, data);
        return fetch(`${https.serverUrl+url}`, {
            method: 'POST',
            headers: {
                // 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    static getNoAuth(url){
        utils.logger(url);
        return fetch(`${https.serverUrl+url}`, {
            method: 'GET',
            headers: {
                // 'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }

    static post(url, data){
        utils.logger(url, data);
        return fetch(`${https.serverUrl+url}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        // .then(res => res.json())
        .catch(err => utils.logger(err))
    }

    static get(url){
        utils.logger(url);
        return fetch(`${https.serverUrl+url}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json'
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
        .catch(err => utils.logger(err))
    }

    static login(data){
        return fetch(`${https.serverUrl}/oauth/token?grant_type=password&username=${data.username}&password=${data.password}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic dGVzdDp0ZXN0MTIz' // test:test123
            },
            // body: JSON.stringify(data)
        })
        // .then(res => res.json())
            .catch(err => utils.logger(err))
    }
}
