import * as React from "react";
import {HTTP} from "../services/http";
import "react-datepicker/dist/react-datepicker.css";
import {utils} from "../services/utils";
import {LeftBar} from "../components/leftbar";


export class NewAccount extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            errors: null,
            model: {
                customer: "",
                // idNumber: "",
                notes: "",
                accountNumber: "",
                bank: "",
                bankBranch: "",
                bankAccountNumber: "",
            },
            customers: [],
            branches: [],
            banks: [],
        }
    }

    http = new HTTP();


    componentDidMount() {
        this.getCustomers();
        this.getBanks();
    }

    submitAccount(e){
        e.preventDefault();
        // utils.logger(this.state.model);

        this.http.createAccount(this.state.model)
            .then(res => {
                utils.logger(res);
                res.json().then(data => {
                    utils.logger(data);
                    if (res.ok) {
                        this.props.history.push('/accounts/list')
                    }else this.setState({ errors: data.errors })
                }).catch(e => utils.logger(e))
            }).catch(e => utils.logger(e))
    }

    getCustomers(){
        this.http.getCustomers()
            .then(res => {
                utils.logger(res);

                res.json().then(data => {
                    utils.logger(data);
                    let model = this.state.model;
                    model.customer = data._embedded.customers[0]._links.self.href;
                    this.setState({
                        customers: data._embedded.customers,
                        model: model
                    })
                }).catch(e => utils.logger(e))
            }).catch(e => utils.logger(e))
    }

    getBanks(){
        this.http.getBanks()
            .then(res => {
                utils.logger(res);

                res.json().then(data => {
                    utils.logger(data);
                    let model = this.state.model;
                    model.bank = data._embedded.banks[0]._links.self.href;
                    this.setState({
                        banks: data._embedded.banks,
                        model: model
                    })
                }).catch(e => utils.logger(e))
            }).catch(e => utils.logger(e))
    }

    getBranches(){
        this.http.getBranches()
            .then(res => {
                utils.logger(res);

                res.json().then(data => {
                    utils.logger(data);
                    this.setState({ branches: data._embedded.branches })
                })
            })
    }

    updateModel(f, v){
        let model = this.state.model;
        model[f] = v;
        this.setState({ model: model });
    }

    render(){
        return (
            <div>
            <div className="row mx-0">
                <div className="col-2">
                    <LeftBar links={[
                        {title: 'Accounts', path: '/accounts/list'},
                        {title: 'New Account', path: '/accounts/new'},
                    ]} />
                </div>
                <div className="col-10">

                <div className="m-5">
                    <h4 className="mb-4">New Account</h4>
                    <form onSubmit={(e)=>this.submitAccount(e)}>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group">
                                <label>Customer</label>
                                <select className="form-control"
                                    onChange={(e)=> this.updateModel('customer', e.target.value) }
                                    value={this.state.model.customer}>
                                    { this.state.customers.map((v, i) => {
                                        return <option key={i} value={v._links.self.href}>{v.firstName} {v.lastName}</option>
                                    }) }
                                </select>
                                { this.state.errors && <small className="text-danger">{this.state.errors.customer}</small>}
                            </div>
                            <div className="form-group">
                                <label>Bank</label>
                                <select className="form-control"
                                        onChange={(e)=> this.updateModel('bank', e.target.value) }
                                        value={this.state.model.bank}>
                                    { this.state.banks.map((v, i) => {
                                        return <option key={i} value={v._links.self.href}>{v.name}</option>
                                    }) }
                                </select>
                                { this.state.errors && <small className="text-danger">{this.state.errors.bank}</small>}
                            </div>
                            <div className="form-group">
                                <label>Bank Branch</label>
                                <input type="text" className="form-control" placeholder="Enter Bank Branch"
                                       onChange={(e)=> this.updateModel('bankBranch', e.target.value) }/>
                                { this.state.errors && <small className="text-danger">{this.state.errors.bankBranch}</small>}
                            </div>
                            <div className="form-group">
                                <label>Bank Account Number</label>
                                <input type="text" className="form-control" placeholder="Enter Bank Account Number"
                                       onChange={(e)=> this.updateModel('bankAccountNumber', e.target.value) }/>
                                { this.state.errors && <small className="text-danger">{this.state.errors.bankAccountNumber}</small>}
                            </div>
                            <div className="form-group">
                                <label>Notes</label>
                                <textarea className="form-control" placeholder="Enter Notes"
                                       onChange={(e)=> this.updateModel('notes', e.target.value) }/>
                                { this.state.errors && <small className="text-danger">{this.state.errors.notes}</small>}
                            </div>
                        </div>
                    </div>
                    { this.state.errors &&
                    <div className="mb-2">
                        <small className="text-danger">{this.state.errors.nonFieldErrors}</small>
                    </div>}
                    <button className="btn btn-primary">Submit</button>
                    </form>
                </div>
                </div>
            </div>
            </div>
        );
    }

}
