import * as React from "react";
import {BaseComponent} from "../../components/basecomponent";
import {Button} from "antd";
import {BreadCrumb} from "../../components/breadcrumb";
import {LoansDatatable} from "./partials/loansdatatable";

export class Loanslist extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }


    render(){
        return (
            <div>
                <BreadCrumb
                  crumbs={[
                      {label: 'Loans', link: '#'},
                  ]}
                />
                <div className="row mx-0 d-flex align-items-center justify-content-center">
                    <div className="col-9 py-5">
                        <Button type="primary"
                                loading={this.state.loading}
                                disabled={this.state.disabled}
                                onClick={()=>this.props.history.push("/loans/new")}>Create New Loan</Button>

                        <LoansDatatable
                            {...this.props}
                            customerId={false}/>
                    </div>
                </div>
            </div>
        );
    }
}
