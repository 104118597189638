import React from "react";
import { Link } from "react-router-dom";
import {utils} from "../services/utils";
import {BaseComponent} from "./basecomponent";

export class NavBar extends BaseComponent {

    urls = {
        user: "/user"
    };

    componentDidMount() {
        if(!localStorage.getItem('token')) this.props.history.push('/login');
        this.get(this.urls.user)
            .then(res => {
                if(!res.body) return this.props.history.push('/login');
                utils.logger('Token: '+localStorage.getItem('token'));
                utils.logger('userProfile: ', res.body);
                utils.save("userProfile", res.body);
            }).catch(e=>utils.logger(e));
    }

    render(){
        return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <Link className="navbar-brand" to="/">MFC Online</Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse pl-lg-2" id="navbarNav">
                    <ul className="navbar-nav">
                    <li className={`nav-item `+ (utils.matchPath('/loans/list') && 'active')}>
                        <Link className="nav-link" to="/loans/list">Loans</Link>
                    </li>
                    <li className={`nav-item `+ (utils.matchPath('/customers/list') && 'active')}>
                        <Link className="nav-link" to="/customers/list">Customers</Link>
                    </li>
                    <li className={`nav-item `+ (utils.matchPath('/ssb') && 'active')}>
                        <Link className="nav-link" to="/ssb">SSB</Link>
                    </li>
                    <li className={`nav-item `+ (utils.matchPath('/users') && 'active')}>
                        <Link className="nav-link" to="/users">Users</Link>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link" href="/">Transactions</a>*/}
                    {/*</li>*/}
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link" href="/">Reports</a>*/}
                    {/*</li>*/}
                    </ul>
                </div>
                <div className="btn btn-link-dark"><small>{utils.get("userProfile") && utils.get("userProfile").username}</small></div>
                <Link to="/loans/new/q" className="btn btn-info mr-2">Loan Calculator</Link>
                <div className="btn btn-light" onClick={()=>{
                    localStorage.clear();
                    this.props.history.push('/login');
                }}>Sign Out</div>
            </nav>
        </div>
        );
    }
}
