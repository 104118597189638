import {HTTP} from "../services/http";
import * as React from "react";
import {utils} from "../services/utils";
import {LeftBar} from "../components/leftbar";
import {Page} from "../components/page";

export class Accountslist extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            accounts: [],
            errors: null
        }
    };

    http = new HTTP();


    componentDidMount(){
        this.getAccounts();
    }

    getAccounts(){
        this.http.getPaginated('/accounts', '&sort=id,desc&page=0')
            .then(res => {
                utils.logger(res);

                res.json()
                    .then(data => {
                        utils.logger(data);
                        if(!res.ok) {
                            // utils.logger(data.error_description);
                            this.setState({ errors: data });
                            return;
                        }
                        this.setState({
                            accounts: data
                        })
                    }).catch(e => utils.logger(e))
            }).catch(e => utils.logger(e))
    }

    getRecords = (records) => {
        utils.logger(records);
        this.setState({
            accounts: records
        })
    };

    render(){
        return (<>
        <div>
        <div className="row mx-0">
            <div className="col-2">
                <LeftBar links={[
                    {title: 'Accounts', path: '/accounts/list'},
                    {title: 'New Account', path: '/accounts/new'},
                ]} />
            </div>
            <div className="col-10">
            <div className="mt-5">
                <div className="mx-5">
                    {this.state.errors &&
                        <div className="alert alert-danger w-50">{this.state.errors.error_description}</div>}
                    { this.state.accounts.page &&
                    <Page
                        uri="/accounts"
                        domain={this.state.accounts}
                        filters="&sort=id,desc"
                        response={this.getRecords}
                        >
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Account Number</th>
                            <th scope="col">Bank</th>
                            <th scope="col">Bank Branch</th>
                            <th scope="col">Bank Account</th>
                            <th scope="col">Created</th>
                        </tr>
                        </thead>
                        <tbody>
                        { this.state.accounts._embedded.accounts.map((v, i)=>{
                            return (<tr key={i}>
                                    <td>{v.customer.firstName}</td>
                                    <td>{v.customer.lastName}</td>
                                    <td>{v.accountNumber}</td>
                                    <td>{v.bank.name}</td>
                                    <td>{v.bankBranch}</td>
                                    <td>{v.bankAccountNumber}</td>
                                    <td>{v.createdAt}</td>
                                </tr>
                            )})}
                        </tbody>
                    </table>
                    </Page> }
                </div>
            </div>
            </div>
        </div>
        </div>
        </>);
    }
}
