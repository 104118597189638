import React from 'react';
import {BaseComponent} from "../../components/basecomponent";
import {DataTable} from "../../components/datatable";
import moment from "moment";
import {utils} from "../../services/utils";
import {Button} from "antd";
import {BreadCrumb} from "../../components/breadcrumb";

export class Users extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      users: {},
      urlParams: {
        size: 3,
        sort: 'id,desc'
      },
      currentPage: 0
    }
  }

  urls = {
    users: "/users"
  };

  componentDidMount() {
    this.getUsers();
  }

  getUsers = () => {
    this.get(this.buildUrl(this.urls.users))
      .then(res => {
        if(!res) return;
        this.setState({users: res.body});
      })
  };

  header = (state) => {
    return (
      <>
        <div className="row table-header">
          <div className="col-4">User</div>
          <div className="col-4">Details</div>
        </div>
        <hr/>
      </>
    )
  };

  row = (row, state) => {
    return(
      <>
        <div className="row">
          <div className="col-4">
            {row.firstName} &nbsp;{row.lastName} <br/>
            {row.username}
          </div>
          <div className="col-4">
            Created: {moment(row.created).format("YYYY:MM:SS hh:mm:ss")} <br/>
            Last Updated: {row.modified && moment(row.modified).format("YYYY:MM:SS hh:mm:ss")}
          </div>
        </div>
        <hr/>
      </>
    )
  };

  pagination = (state) => {
    return super.pagination(this.state.users, this.getUsers)
  };

  activate = (target) => {
    utils.logger("activating -> "+target.firstName)
  };

  render() {
    return (
      <div>
        <BreadCrumb
          crumbs={[
            {label: 'Users', link: '#'},
          ]}
        />

        <div className="row mx-0 d-flex align-items-center justify-content-center">

          <div className="col-9 py-5">

            <Button type="primary"
                    loading={this.state.loading}
                    disabled={this.state.disabled}
                    onClick={()=>this.props.history.push("/users/new")}>Create User</Button>

            <div className={`ui ` + (this.state.loading && `active loader`)}/>
            <DataTable
              options={{
                data: this.state.users.content,
                header: this.header,
                row: this.row,
                pagination: this.pagination,
                actions: [
                  {
                    bulk: false,
                    label: "View",
                    onAction: (target) => this.activate(target)
                  },
                  {
                    bulk: true,
                    label: "Activate",
                    onAction: (target) => this.activate(target)
                  },
                  {
                    bulk: true,
                    label: "Deactivate",
                    onAction: (target) => this.activate(target)
                  },
                ],
                postAction: this.getUsers,
                search: {
                  onSearch: (searchString) => {
                    if(!searchString) return;
                    this.addUrlParams({
                      firstName: "EQ,"+searchString+",OR",
                      lastName: "EQ,"+searchString+",OR"
                    }).then(()=>this.getUsers());
                  },
                },
                filters: {
                  display: this.state.urlParams,
                  fields: [
                    { label: "Size", name: "size", type: "select", options: [ "10", "20", "50", "100" ] },
                    { label: "Date", name: "created", type: "input" },
                  ],
                  onAdd: (name, value)=>{
                    if(!value) return;
                    this.addUrlParams({
                      [name]: this.mapValue(name, value)
                    }).then(()=>this.getUsers());
                  },
                  onRemove: (name) => {
                    let params = this.state.urlParams;
                    params = delete params[name];
                    this.addUrlParams(params)
                      .then(()=>this.getUsers());
                  },
                }
              }}
            />
          </div>

        </div>

      </div>
    );
  }
}
